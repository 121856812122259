import React, { useRef, useMemo, useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { PuzzlePiece as PuzzlePieceType } from './PuzzleControls';
import PuzzlePiece from './PuzzlePiece';
import { PiecePosition } from '../utils/pieceDistance';
import { PuzzleSection as PuzzleSectionType } from '../utils/sectionManagement';

interface PuzzleSectionProps {
  sectionId: string;
  pieces: PuzzlePieceType[];
  piecePositions: Record<string, PiecePosition>;
  sectionPosition: PiecePosition;
  pieceSize: number;
  boardWidth: number;
  boardHeight: number;
  maxX: number;
  maxY: number;
  image?: HTMLImageElement;
  onMove: (sectionId: string, deltaX: number, deltaY: number) => void;
  onDrop: (sectionId: string, newPosition: PiecePosition) => void;
  lastMergeTimestamp?: number;
  onPickup: (sectionId: string) => void;
}

export default function PuzzleSection({
  sectionId,
  pieces,
  piecePositions,
  sectionPosition,
  pieceSize,
  boardWidth,
  boardHeight,
  maxX,
  maxY,
  image,
  onMove,
  onDrop,
  lastMergeTimestamp,
  onPickup
}: PuzzleSectionProps) {
  const sectionRef = useRef(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [slowMoving, setSlowMoving] = useState(true);

  // Effect to handle merge animations
  useEffect(() => {
    if (lastMergeTimestamp) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setSlowMoving(false);
      timeoutRef.current = setTimeout(() => {
        setSlowMoving(true);
      }, 500);
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [lastMergeTimestamp]);

  // Calculate section dimensions
  const { width: sectionWidth, height: sectionHeight } = useMemo(() => {
    const positions = pieces.map(piece => piecePositions[piece.id]);
    const minX = Math.min(...positions.map(p => p.x));
    const maxX = Math.max(...positions.map(p => p.x));
    const minY = Math.min(...positions.map(p => p.y));
    const maxY = Math.max(...positions.map(p => p.y));

    return {
      width: maxX - minX + pieceSize,
      height: maxY - minY + pieceSize
    };
  }, [pieces, piecePositions, pieceSize]);

  return (
    <Draggable
      nodeRef={sectionRef}
      position={sectionPosition}
      onStart={() => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setSlowMoving(false);
        onPickup(sectionId);
      }}
      onDrag={(e, data) => {
        const deltaX = data.x - sectionPosition.x;
        const deltaY = data.y - sectionPosition.y;
        onMove(sectionId, deltaX, deltaY);
      }}
      onStop={(e, data) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          setSlowMoving(true);
        }, 500);
        onDrop(sectionId, { x: data.x, y: data.y });
      }}
      bounds={{
        left: 0,
        top: 0,
        right: boardWidth - pieceSize,
        bottom: boardHeight - pieceSize
      }}
    >
      <div
        ref={sectionRef}
        className="absolute cursor-move"
        style={{
          touchAction: "none",
          willChange: "transform",
          width: sectionWidth,
          height: sectionHeight,
          transition: !slowMoving ? "none" : "transform 0.8s cubic-bezier(0.34, 1.15, 0.64, 1)"
        }}
      >
        {pieces.map(piece => (
          <PuzzlePiece
            key={piece.id}
            piece={piece}
            image={image}
            width={pieceSize}
            height={pieceSize}
            x={piecePositions[piece.id].x - sectionPosition.x}
            y={piecePositions[piece.id].y - sectionPosition.y}
            maxX={maxX}
            maxY={maxY}
          />
        ))}
      </div>
    </Draggable>
  );
} 