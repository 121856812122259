import React, { useCallback, useState } from 'react';

interface ImageUploadProps {
    onImageLoad: (image: HTMLImageElement) => void;
}

export default function ImageUpload({ onImageLoad }: ImageUploadProps) {
    const [isDragging, setIsDragging] = useState(false);
    const [previewUrl, setPreviewUrl] = useState<string>();

    const handleFile = useCallback((file: File) => {
        if (!file.type.startsWith('image/')) {
            alert('Please upload an image file');
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const dataUrl = e.target?.result as string;
            if (dataUrl) {
                setPreviewUrl(dataUrl);
                const img = new Image();
                img.onload = () => onImageLoad(img);
                img.src = dataUrl;
            }
        };
        reader.readAsDataURL(file);
    }, [onImageLoad]);

    const handleDrop = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file) {
            handleFile(file);
        }
    }, [handleFile]);

    const handleDragOver = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);

    const handleFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            handleFile(file);
        }
    }, [handleFile]);

    return (
        <div className="w-full">
            <div 
                className={`
                    border-2 border-dashed rounded-lg p-4 text-center cursor-pointer
                    transition-colors duration-200
                    ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}
                `}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onClick={() => document.getElementById('file-input')?.click()}
            >
                {previewUrl ? (
                    <div className="relative aspect-square">
                        <img 
                            src={previewUrl} 
                            alt="Preview" 
                            className="w-full h-full object-cover rounded"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-10 transition-opacity duration-200" />
                    </div>
                ) : (
                    <div className="py-4">
                        <div className="text-gray-500">
                            Drag and drop an image here<br />
                            or click to select
                        </div>
                    </div>
                )}
            </div>
            <input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={handleFileInput}
                className="hidden"
            />
        </div>
    );
}
