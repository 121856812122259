import React, { useState } from 'react';
import { motion } from 'framer-motion';
import AudioPlayer, { audioControls } from './AudioPlayer';
import { songs } from '../songs-config';

export default function AudioControls() {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isAudioReady, setIsAudioReady] = useState(false);
    const [currentSongIndex, setCurrentSongIndex] = useState(0);

    const handlePlayPause = () => {
        if (!isAudioReady) {
            setIsAudioReady(true);
        } else {
            audioControls.togglePlayPause();
        }
    };

    const handleNext = () => {
        setCurrentSongIndex((prev) => (prev + 1) % songs.length);
    };

    const currentSong = songs[currentSongIndex];

    return (
        <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="absolute top-4 left-4 bg-black/40 backdrop-blur-md text-white p-3 rounded-lg z-50 flex items-center space-x-4"
        >
            <div className="font-semibold">{currentSong.songName}</div>
            <div className="flex space-x-3">
                <button 
                    onClick={handlePlayPause}
                    className="hover:text-green-400 transition-colors"
                >
                    {isPlaying ? '⏸️' : '▶️'}
                </button>
                <button 
                    onClick={handleNext}
                    className="hover:text-green-400 transition-colors"
                >
                    ⏭️
                </button>
            </div>

            <AudioPlayer 
                src={currentSong.audioFile}
                readyToPlay={isAudioReady}
                onFinished={handleNext}
                onPlayStateChange={setIsPlaying}
            />
        </motion.div>
    );
} 