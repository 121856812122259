import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { OpeningState } from '../types';
import PuzzleBox from './PuzzleBox';
import bagPuzzle from '../../../assets/images/bag2.png';

interface BoxOpeningProps {
  openingState: OpeningState;
  onStart: () => void;
  onReturn: () => void;
}

export interface BoxOpeningHandle {
  handleReturn: () => void;
}

interface BoxEdgeProps {
  className?: string;
  gradientFrom?: string;
  gradientTo?: string;
  direction?: 'to right' | 'to bottom' | 'to top' | 'to left';
  children?: React.ReactNode;
}

function BoxEdge({ className = '', gradientFrom = 'rgb(229,231,235)', gradientTo = 'rgb(243,244,246)', direction = 'to right', children }: BoxEdgeProps) {
  return (
    <div
      className={`absolute bg-gray-100 rounded-sm overflow-hidden transition-all duration-500 ${className}`}
      style={{
        background: `linear-gradient(${direction}, ${gradientFrom}, ${gradientTo})`,
        transformStyle: "preserve-3d",
      }}
    >
      <div
        className="absolute top-1/2 left-1/2 -translate-y-1/2"
        style={{
          transform: direction === 'to left' ? 
            "translate(-50%, -50%) rotateZ(-90deg) rotateY(-45deg) scaleX(0.7)" :
            "translate(-50%, -50%) rotateX(45deg)",
          transformOrigin: "center center",
        }}
      >
        {children}
      </div>
    </div>
  );
}

function InsideOfBox() {
  return (
    <div className="absolute inset-0 transform-gpu">
      {/* Main white surface with shadow gradients */}
      <div className="absolute inset-4 bg-white rounded-sm shadow-inner">
        {/* Bag puzzle image */}
        <div className="absolute inset-0 flex items-center justify-center translate-x-4 translate-y-4">
          <img 
            src={bagPuzzle} 
            alt="Puzzle Bag"
            className="w-full h-full object-fill"
          />
        </div>
        {/* Centered Bezzel text */}
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-amber-900 text-4xl chunky-dark font-bold text-center tracking-widest" style={{textShadow: "0px 0px 5px rgba(1000, 0, 0,0.15)"}}>
            <br/>Kellymas
            <br/>x
            <br/>Bezzel
            <br/>Exclusive
          </span>
        </div>

        {/* Left shadow gradient */}
        <div
          className="absolute left-0 top-0 bottom-0 w-16"
          style={{
            background:
              "linear-gradient(to right, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.2) 20%, transparent 100%)",
          }}
        />
        {/* Top shadow gradient */}
        <div
          className="absolute top-0 left-0 right-0 h-16"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.2) 20%, transparent 100%)",
          }}
        />
      </div>

      {/* Right edge */}
      <div
        className="absolute top-4 bottom-4 right-0 w-4 bg-gray-100 origin-left rounded-r-sm side-left"
        style={{
          background:
            "linear-gradient(to right, rgb(209,211,216), rgb(243,244,246))",
          transformStyle: "preserve-3d",
          borderTop: "1px solid rgba(0,0,0,0.2)",
          borderLeft: "1px solid rgba(0,0,0,0.2)", 
          borderBottom: "0.5px solid rgba(0,0,0,0.2)",
          borderRight: "3px solid rgba(0,0,0)"
        }}
      />

      {/* Bottom edge */}
      <div
        className="absolute left-4 right-4 bottom-0 h-4 bg-gray-100 origin-top rounded-b-sm side-top"
        style={{
          background:
            "linear-gradient(to bottom, rgb(209,211,216), rgb(243,244,246))",
          transformStyle: "preserve-3d",
          borderTop: "1px solid rgba(0,0,0,0.2)",
          borderLeft: "1px solid rgba(0,0,0,0.2)",
          borderRight: "1px solid rgba(0,0,0,0.2)",
          borderBottom: "3px solid rgba(0,0,0)"
        }}
      />

      {/* Left edge */}
      <div
        className="absolute top-8 bottom-0 left-4 w-4 bg-gray-100 origin-right rounded-l-sm side-left"
        style={{
          background:
            "linear-gradient(to left, rgb(153,27,27), rgb(127,29,29))",
          transformStyle: "preserve-3d",
          borderTop: "0.5px solid rgba(0,0,0,0.2)",
          borderLeft: "1px solid rgba(0,0,0,0.2)",
          borderBottom: "1px solid rgba(0,0,0,0.2)",
          borderRight: "3px solid rgba(0,0,0)"
        }}
      />

      {/* Top edge */}
      <div
        className="absolute left-8 right-0 top-4 h-4 bg-gray-100 origin-bottom rounded-t-sm side-top"
        style={{
          background: "linear-gradient(to top, rgb(153,27,27), rgb(127,29,29))",
          transformStyle: "preserve-3d",
          borderTop: "1px solid rgba(0,0,0,0.2)",
          borderLeft: "1px solid rgba(0,0,0,0.2)",
          borderRight: "1px solid rgba(0,0,0,0.2)",
          borderBottom: "3px solid rgba(0,0,0)"
        }}
      />

      {/* Inner shadow */}
      <div className="absolute inset-4 shadow-inner rounded-sm" />
    </div>
  );
}

const BoxOpening = forwardRef<BoxOpeningHandle, BoxOpeningProps>(({ 
  openingState, 
  onStart, 
  onReturn 
}, ref) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [isReturning, setIsReturning] = useState(false);
  const [isOpening, setIsOpening] = useState(false);

  const handleReturn = () => {
    if (!boxRef.current) return;

    setIsReturning(true);
    setIsOpening(false);
    const box = boxRef.current;
    box.style.transform = 'translate(0, 0) scale(1)';

    // Wait for animation to complete before calling onReturn
    setTimeout(onReturn, 1000);
  };

  useImperativeHandle(ref, () => ({
    handleReturn
  }));

  useEffect(() => {
    if (!boxRef.current) return;

    const box = boxRef.current;
    box.style.transform = 'translate(0, 0) scale(1)';
    void box.offsetHeight;

    requestAnimationFrame(() => {
      box.style.transform = `translate(calc(50vw - 50% - ${openingState.position.x}px), calc(50vh - 50% - ${openingState.position.y}px)) scale(1.5)`;
      // Start opening animation after moving to center
      setTimeout(() => setIsOpening(true), 1000);
    });
  }, [openingState]);

  return (
    <div className="fixed inset-0">
      {/* Inside of box - fixed in center */}
      <div
        className="absolute transition-opacity duration-150"
        style={{
          left: '50%',
          top: '50%',
          width: openingState.position.width * 1.5,
          height: openingState.position.height * 1.5,
          transform: 'translate(-50%, -50%)',
          opacity: isOpening ? 1 : 0,
        }}
      >
        <InsideOfBox />
      </div>

      {/* Animated outer box */}
      <div
        ref={boxRef}
        className="absolute transition-all duration-1000 ease-out"
        style={{
          left: openingState.position.x,
          top: openingState.position.y,
          width: openingState.position.width,
          height: openingState.position.height,
        }}
      >
        <div 
          className="relative w-full h-full"
          style={{
            animation: isOpening ? 'openBox 1.3s ease-out forwards' : 'none',
            transformOrigin: 'center center',
          }}
        >
          <PuzzleBox
            preset={openingState.preset}
            index={0}
            isLoaded={true}
            onImageLoad={() => {}}
            onClick={() => {}}
            withMotion={false}
          />
        </div>

        <button
          onClick={onStart}
          className="absolute left-1/2 -translate-x-1/2 -bottom-16 bg-green-800/40 hover:bg-green-900/60 backdrop-blur-sm text-white py-3 px-8 rounded-lg transition-all duration-200 transform hover:scale-105 shadow-2xl"
          style={{
            animation: isReturning ? 'none' : 'fadeInButton 0.5s ease-out 1.6s forwards',
            opacity: 0,
          }}
        >
          Start Puzzle
        </button>

        <style>
          {`
            @keyframes openBox {
              0% {
                transform: scale(1) rotateY(0deg) translate(0, 0);
              }
              23% { /* 0.3s */
                transform: scale(1.1) rotateY(20deg) translate(0, 0);
              }
              23.1% { /* Hold for 0.3s */
                transform: scale(1.1) rotateY(20deg) translate(0, 0);
              }
              100% {
                transform: scale(1.1) rotateY(20deg) translate(30%, 150%);
              }
            }

            @keyframes fadeInButton {
              0% {
                opacity: 0;
                transform: translate(-50%, 20px);
              }
              100% {
                opacity: 1;
                transform: translate(-50%, 0);
              }
            }
          `}
        </style>
      </div>
    </div>
  );
});

export default BoxOpening; 