import React from 'react';
import { PARAMETER_CONFIGS, PRESET_CONFIGS, generateRandomSeed } from '../config';

export interface PuzzleParams {
    dimensions: { width: number; height: number };
    piecesAcross: number;
    piecesDown: number;
    tabSize: { min: number; max: number };
    tabWidth: { min: number; max: number };
    tabCurvature: { min: number; max: number };
    neckSize: { min: number; max: number };
    neckSmoothing: { min: number; max: number };
    offset: { min: number; max: number };
    tabSway: { min: number; max: number };
    seed: string;
    debug: boolean;
}

export interface PuzzlePiece {
    id: string;
    x: number;
    y: number;
    svgPath: string;
    edges: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
}

interface PuzzleControlsProps {
    params: PuzzleParams;
    onParamsChange: (params: PuzzleParams) => void;
    onGenerate: () => void;
}

export default function PuzzleControls({ params, onParamsChange, onGenerate }: PuzzleControlsProps) {
    const handleChange = (field: keyof PuzzleParams, value: any) => {
        onParamsChange({ ...params, [field]: value });
    };

    const handleRangeChange = (field: keyof typeof PARAMETER_CONFIGS, subfield: 'min' | 'max', value: number) => {
        onParamsChange({
            ...params,
            [field]: {
                ...params[field],
                [subfield]: value
            }
        });
    };

    const loadPreset = (presetName: keyof typeof PRESET_CONFIGS) => {
        // Preserve current dimensions and pieces count when switching presets
        const currentDimensions = params.dimensions;
        const currentPiecesAcross = params.piecesAcross;
        const currentPiecesDown = params.piecesDown;
        
        onParamsChange({
            ...PRESET_CONFIGS[presetName],
            dimensions: currentDimensions,
            piecesAcross: currentPiecesAcross,
            piecesDown: currentPiecesDown
        });
    };

    return (
        <div className="space-y-2">
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Preset Configuration
                </label>
                <select
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    onChange={(e) => loadPreset(e.target.value as keyof typeof PRESET_CONFIGS)}
                >
                    {Object.keys(PRESET_CONFIGS).map(preset => (
                        <option key={preset} value={preset}>{preset}</option>
                    ))}
                </select>
            </div>

            <div>
                <h3 className="text-lg font-medium mb-2">Grid</h3>
                <div className="space-y-2">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Pieces Across</label>
                        <input
                            type="number"
                            min="1"
                            value={params.piecesAcross}
                            onChange={(e) => handleChange('piecesAcross', parseInt(e.target.value))}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Pieces Down</label>
                        <input
                            type="number"
                            min="1"
                            value={params.piecesDown}
                            onChange={(e) => handleChange('piecesDown', parseInt(e.target.value))}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>
                </div>
            </div>

            <div>
                <h3 className="text-lg font-medium mb-2">Tab Properties</h3>
                <div className="space-y-2">
                    {Object.entries(PARAMETER_CONFIGS).map(([key, config]) => (
                        <div key={key}>
                            <label className="block text-sm font-medium text-gray-700">
                                {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                            </label>
                            <div className="grid grid-cols-2 gap-4 mt-1">
                                <div>
                                    <div className="text-xs text-gray-500">Min</div>
                                    <input
                                        type="range"
                                        min={config.min}
                                        max={config.max}
                                        step={config.step}
                                        value={params[key as keyof typeof PARAMETER_CONFIGS].min}
                                        onChange={(e) => handleRangeChange(key as keyof typeof PARAMETER_CONFIGS, 'min', parseFloat(e.target.value))}
                                        className="w-full"
                                    />
                                    <div className="text-sm">{params[key as keyof typeof PARAMETER_CONFIGS].min}</div>
                                </div>
                                <div>
                                    <div className="text-xs text-gray-500">Max</div>
                                    <input
                                        type="range"
                                        min={config.min}
                                        max={config.max}
                                        step={config.step}
                                        value={params[key as keyof typeof PARAMETER_CONFIGS].max}
                                        onChange={(e) => handleRangeChange(key as keyof typeof PARAMETER_CONFIGS, 'max', parseFloat(e.target.value))}
                                        className="w-full"
                                    />
                                    <div className="text-sm">{params[key as keyof typeof PARAMETER_CONFIGS].max}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div>
                <h3 className="text-lg font-medium mb-2">Seed</h3>
                <div className="flex gap-2 items-center">
                    <input
                        type="text"
                        value={params.seed}
                        onChange={(e) => handleChange('seed', e.target.value)}
                        className="flex-1 px-2 py-1 border rounded"
                    />
                    <button
                        onClick={() => handleChange('seed', generateRandomSeed())}
                        className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Random
                    </button>
                </div>
            </div>

            <div className="flex items-center">
                <input
                    type="checkbox"
                    checked={params.debug}
                    onChange={(e) => handleChange('debug', e.target.checked)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="ml-2 block text-sm text-gray-900">
                    Debug Mode
                </label>
            </div>

            <button
                onClick={onGenerate}
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
                Generate
            </button>
        </div>
    );
}
