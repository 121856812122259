import React, { useState, useRef } from "react";
import { PUZZLE_PRESETS } from "../puzzle-presets";
import { PuzzlePreset, BoxPosition } from "../types";
import PuzzleBox from "./PuzzleBox";

interface PuzzleSelectorProps {
  onPuzzleSelected: () => void;
  onPuzzleBoxClick: (preset: PuzzlePreset, position: BoxPosition) => void;
}

export default function PuzzleSelector({
  onPuzzleSelected,
  onPuzzleBoxClick,
}: PuzzleSelectorProps) {
  const [loadedImages, setLoadedImages] = useState<Record<number, boolean>>({});
  const boxRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleBoxClick = (preset: PuzzlePreset, index: number) => {
    const box = boxRefs.current[index];
    if (!box) return;

    const rect = box.getBoundingClientRect();
    onPuzzleBoxClick(preset, {
      x: rect.left,
      y: rect.top,
      width: rect.width,
      height: rect.height,
    });
  };

  return (
    <div className="max-w-6xl mx-auto p-8">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-white mb-4">
          Merry Kellymas 🎄
        </h2>
        <p className="text-gray-200 text-lg">And a happy puzzling year!</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {PUZZLE_PRESETS.map((preset, index) => (
          <div key={index} ref={el => boxRefs.current[index] = el}>
            <PuzzleBox
              preset={preset}
              index={index}
              isLoaded={!!loadedImages[index]}
              onImageLoad={() => setLoadedImages(prev => ({ ...prev, [index]: true }))}
              onClick={() => handleBoxClick(preset, index)}
            />
          </div>
        ))}
      </div>

      <div className="text-center mt-12">
        <a
          href="/bezzel/cutter"
          className="inline-block bg-green-600/80 hover:bg-green-700/80 text-white py-3 px-6 rounded-lg transition-colors duration-200"
        >
          Create Your Own Puzzle
        </a>
      </div>
    </div>
  );
}
