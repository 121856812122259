import beginningtolook from "../../assets/audio/beginningtolook.mp3";
import lastchristmas from "../../assets/audio/lastchristmas.mp3";
import thischristmas from "../../assets/audio/this.mp3";
import newyears from "../../assets/audio/newyears.mp3";
import nonsense from "../../assets/audio/nonsense.mp3";
import treefarm from "../../assets/audio/treefarm.mp3";
import underneath from "../../assets/audio/underneath.mp3";
import wonderland from "../../assets/audio/wonderland.mp3";
import alliwant from "../../assets/audio/alliwant.mp3";

export type Song = {
  songName: string;
  audioFile: string;
};

export const songs: Song[] = [
  {
    songName: "This Kellymas",
    audioFile: thischristmas,
  },
  {
    songName: "A Nonsense Kellymas",
    audioFile: nonsense,
  },
  {
    songName: "Last Kellymas",
    audioFile: lastchristmas,
  },
  {
    songName: "It's beginning to look a lot like Kellymas",
    audioFile: beginningtolook,
  },
  {
    songName: "Underneath the tree",
    audioFile: underneath,
  },
  {
    songName: "All I want for Kellymas is you",
    audioFile: alliwant,
  },
  {
    songName: "Kellymas Tree Farm (old timey version)",
    audioFile: treefarm,
  },
  {
    songName: "Winter Wonderland",
    audioFile: wonderland,
  },
  {
    songName: "is it new years yet?",
    audioFile: newyears,
  },
];
