import { useParams } from 'react-router-dom';
import { LETTER_2023, LETTER_2024 } from '../../data/letters';
import Mailbox from './Mailbox';

export default function MailboxRouter() {
    const { year } = useParams();
    
    const letter = year === '2024' ? LETTER_2024 : LETTER_2023;
    
    return <Mailbox letter={letter} />;
} 