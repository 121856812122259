import puzzle45 from "../../assets/images/puzzle-candidates/SNAP_00045.jpg";
import puzzle52 from "../../assets/images/puzzle-candidates/SNAP_00052.jpg";
import puzzle66 from "../../assets/images/puzzle-candidates/SNAP_00066.jpg";
import puzzle68 from "../../assets/images/puzzle-candidates/SNAP_00068.jpg";
import puzzle71 from "../../assets/images/puzzle-candidates/SNAP_00071.jpg";
import puzzle74 from "../../assets/images/puzzle-candidates/SNAP_00074.jpg";
import puzzle78 from "../../assets/images/puzzle-candidates/SNAP_00078.jpg";
import puzzle80 from "../../assets/images/puzzle-candidates/SNAP_00080.jpg";
import puzzle81 from "../../assets/images/puzzle-candidates/SNAP_00081.jpg";
import puzzle83 from "../../assets/images/puzzle-candidates/SNAP_00083.jpg";
import puzzle84 from "../../assets/images/puzzle-candidates/SNAP_00084.jpg";
import puzzle59 from "../../assets/images/puzzle-candidates/SNAP_00059.jpg";

export interface PuzzlePreset {
    imageUrl: string;
    piecesAcross: number;
    piecesDown: number;
}

export const PUZZLE_PRESETS: PuzzlePreset[] = [
  {
    imageUrl: puzzle68,
    piecesAcross: 6,
    piecesDown: 6,
  },
  {
    imageUrl: puzzle71,
    piecesAcross: 5,
    piecesDown: 7,
  },
  {
    imageUrl: puzzle45,
    piecesAcross: 7,
    piecesDown: 5,
  },
  {
    imageUrl: puzzle84,
    piecesAcross: 5,
    piecesDown: 7,
  },
  {
    imageUrl: puzzle74,
    piecesAcross: 5,
    piecesDown: 7,
  },
  {
    imageUrl: puzzle52,
    piecesAcross: 6,
    piecesDown: 5,
  },
  {
    imageUrl: puzzle59,
    piecesAcross: 5,
    piecesDown: 7,
  },
  {
    imageUrl: puzzle78,
    piecesAcross: 6,
    piecesDown: 5,
  },
  {
    imageUrl: puzzle80,
    piecesAcross: 4,
    piecesDown: 6,
  },
  {
    imageUrl: puzzle66,
    piecesAcross: 5,
    piecesDown: 7,
  },
  {
    imageUrl: puzzle81,
    piecesAcross: 5,
    piecesDown: 7,
  },
  {
    imageUrl: puzzle83,
    piecesAcross: 5,
    piecesDown: 7,
  },
];
