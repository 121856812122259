import { PuzzlePiece, PuzzleParams } from '../components/PuzzleControls';

// Constants for piece generation
const PIECE_SIZE = 100; // Base size for each piece

interface Point {
    x: number;
    y: number;
}

function createPoint(x: number, y: number): Point {
    return { x, y };
}

interface Range {
    min: number;
    max: number;
}

interface EdgeProperties {
    tabSize: number;
    tabWidth: number;
    neckSize: number;
    tabCurvature: number;
    neckSmoothing: number;
    offsetA: number;
    offsetC: number;
    tabSway: number;
}

function randomizeProperty(random: Random, range: Range, debug: boolean): number {
    if (debug) {
        // In debug mode, randomly pick either min or max
        return random.randomBoolean() ? range.min : range.max;
    }
    // Normal mode: pick a value within the range
    return range.min + (range.max - range.min) * random.random();
}

function generateEdgeProperties(random: Random, params: PuzzleParams): EdgeProperties {
    const offsetA = randomizeProperty(random, params.offset, params.debug);
    // Generate offsetC within ±3px of offsetA
    const offsetDeltaRange = { min: -3, max: 3 };
    const offsetDelta = randomizeProperty(random, offsetDeltaRange, params.debug);
    // Clamp offsetC to the allowed range
    const offsetC = Math.max(params.offset.min, Math.min(params.offset.max, offsetA + offsetDelta));

    return {
        tabSize: randomizeProperty(random, params.tabSize, params.debug),
        tabWidth: randomizeProperty(random, params.tabWidth, params.debug),
        neckSize: randomizeProperty(random, params.neckSize, params.debug),
        tabCurvature: randomizeProperty(random, params.tabCurvature, params.debug),
        neckSmoothing: randomizeProperty(random, params.neckSmoothing, params.debug),
        tabSway: randomizeProperty(random, params.tabSway, params.debug),
        offsetA,
        offsetC
    };
}

/**
 * Puzzle Piece Edge Construction
 * 
 * For a horizontal tab (indent similar but inverted):
 * 
 *    start                                              end
 *      *----------------------------------------------*
 *      |                                              |
 *      |                    bulge                     |
 *      |                 cp1 *-* cp2                 |
 *      |                /         \                  |
 *      |              /            \                |
 *      |            n1*             *n2            |
 *      |    smooth  |               |   smooth     |
 *      |    curve   |               |   curve      |
 *      |          * |               | *            |
 *      *---------*  |               |  *-----------*
 *     s          A  |               |  C           e
 *                   |<- tab width ->|
 * 
 * SVG Path Commands Used:
 * L (Line To): Draws straight line from current point to (x,y)
 *    Used for: s->A, and C->e
 * 
 * Q (Quadratic Curve): Draws curve using one control point
 *    Used for: A->n1 and n2->C (the smooth transitions)
 *    Control point moves vertically to create roundness
 *    When smoothing=0: these become straight lines (L)
 * 
 * C (Cubic Curve): Draws curve using two control points
 *    Used for: n1->n2 (the bulge)
 *    Control points cp1,cp2 control the bulge shape
 * 
 * Parameters Effect on Shape:
 * - tabSize: Total height from base to peak of bulge
 * - tabWidth: Horizontal distance between A and C
 * - neckSize: Vertical distance from A to n1 (and C to n2)
 * - neckSmoothing: How far the Q control point moves vertically
 *   - At 0: No curve (sharp corners at A and C)
 *   - At 1: Maximum curve (smooth transition)
 * - tabCurvature: How far cp1,cp2 spread horizontally
 * 
 * Path Construction (with smoothing):
 * 1. L: Line to A
 * 2. Q: Smooth curve A to n1 (control point moves up by neckHeight * smoothing)
 * 3. C: Bulge curve n1 to n2 (using cp1,cp2)
 * 4. Q: Smooth curve n2 to C (control point moves up by neckHeight * smoothing)
 * 5. L: Line to end
 */

class Random {
    private seed: number;
    private m = 0x80000000; // 2**31
    private a = 1103515245;
    private c = 12345;

    constructor(seed: string) {
        this.seed = this.hashString(seed);
    }

    private hashString(str: string): number {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return Math.abs(hash);
    }

    random(): number {
        this.seed = (this.a * this.seed + this.c) % this.m;
        return this.seed / this.m;
    }

    randomBoolean(probability: number = 0.5): boolean {
        return this.random() < probability;
    }
}

function randomEdge(random: Random): number {
    return random.randomBoolean() ? 1 : -1;
}

function isRange(value: any): value is Range {
    return value && typeof value === 'object' && 'min' in value && 'max' in value;
}

function createEdgePath(start: Point, end: Point, edgeType: number, params: PuzzleParams, edgeProps: EdgeProperties | PuzzleParams): string {
    // If we're using default params, convert ranges to their midpoints
    const props: EdgeProperties = {
        tabSize: isRange(edgeProps.tabSize) ? 
            (edgeProps.tabSize.min + edgeProps.tabSize.max) / 2 : 
            edgeProps.tabSize as number,
        tabWidth: isRange(edgeProps.tabWidth) ? 
            (edgeProps.tabWidth.min + edgeProps.tabWidth.max) / 2 : 
            edgeProps.tabWidth as number,
        neckSize: isRange(edgeProps.neckSize) ? 
            (edgeProps.neckSize.min + edgeProps.neckSize.max) / 2 : 
            edgeProps.neckSize as number,
        tabCurvature: isRange(edgeProps.tabCurvature) ? 
            (edgeProps.tabCurvature.min + edgeProps.tabCurvature.max) / 2 : 
            edgeProps.tabCurvature as number,
        neckSmoothing: isRange(edgeProps.neckSmoothing) ? 
            (edgeProps.neckSmoothing.min + edgeProps.neckSmoothing.max) / 2 : 
            edgeProps.neckSmoothing as number,
        offsetA: 'offset' in edgeProps ? 
            (edgeProps.offset.min + edgeProps.offset.max) / 2 : 
            (edgeProps as EdgeProperties).offsetA,
        offsetC: 'offset' in edgeProps ? 
            (edgeProps.offset.min + edgeProps.offset.max) / 2 : 
            (edgeProps as EdgeProperties).offsetC,
        tabSway: 'tabSway' in edgeProps && isRange(edgeProps.tabSway) ? 
            (edgeProps.tabSway.min + edgeProps.tabSway.max) / 2 : 
            (edgeProps as EdgeProperties).tabSway
    };

    if (edgeType === 0) {
        return `L ${end.x} ${end.y}`;
    }

    const dx = end.x - start.x;
    const dy = end.y - start.y;
    const isHorizontal = Math.abs(dx) > Math.abs(dy);

    if (isHorizontal) {
        const width = Math.abs(dx);
        const direction = dx > 0 ? 1 : -1;
        const bulgeHeight = props.tabSize * edgeType;
        const neckHeight = props.neckSize * edgeType;
        const totalHeight = bulgeHeight + neckHeight;
        
        const anchorDistance = (width - props.tabWidth) / 2;
        
        let isLeftSide = start.x < end.x;
        let [yA, yC] = isLeftSide ? 
            [props.offsetA, props.offsetC] : 
            [props.offsetC, props.offsetA];
        yA *= edgeType;
        yC *= edgeType;

        // First anchor point (A)
        const A = createPoint(
            start.x + (anchorDistance * direction),
            start.y + yA
        );
        
        // Second anchor point (C)
        const C = createPoint(
            end.x - (anchorDistance * direction),
            start.y + yC
        );

        // Neck points (N1 and N2)
        const n1 = createPoint(
            A.x,
            start.y + neckHeight
        );
        const n2 = createPoint(
            C.x,
            n1.y
        );

        // Middle point (peak of the bulge)
        const midX = start.x + (width/2 * direction) + props.tabSway;
        const midY = n1.y + bulgeHeight;

        // Control points for the main curve - based on tab height
        const curveOffset = 40 * props.tabCurvature;
        const cp1 = createPoint(
            midX - (curveOffset * direction),
            midY
        );
        const cp2 = createPoint(
            midX + (curveOffset * direction),
            midY
        );

        if (props.neckSmoothing === 0) {
            // No smoothing - straight lines to neck points
            return [
                `L ${A.x} ${A.y}`,
                `L ${n1.x} ${n1.y}`,
                `C ${cp1.x} ${cp1.y}, ${cp2.x} ${cp2.y}, ${n2.x} ${n2.y}`,
                `L ${C.x} ${C.y}`,
                `L ${end.x} ${end.y}`
            ].join(' ');
        } else {
            // With smoothing - use quadratic curves for smoother transitions
            // For a horizontal edge, we're either moving left->right (direction=1) or right->left (direction=-1)
            // edgeType=1 means this is a tab (bulges up), edgeType=-1 means this is an indent (bulges down)
            
            let YFractionBetweenBaselineAndNeck = 0.25;
            
            const firstControl = createPoint(
                A.x + (props.neckSmoothing * direction),
                A.y + (n1.y - A.y) * YFractionBetweenBaselineAndNeck
            );
            
            const secondControl = createPoint(
                C.x - (props.neckSmoothing * direction),
                C.y + (n2.y - C.y) * YFractionBetweenBaselineAndNeck
            );

            return [
                `L ${A.x} ${A.y}`,
                `Q ${firstControl.x} ${firstControl.y}, ${n1.x} ${n1.y}`,
                `C ${cp1.x} ${cp1.y}, ${cp2.x} ${cp2.y}, ${n2.x} ${n2.y}`,
                `Q ${secondControl.x} ${secondControl.y}, ${C.x} ${C.y}`,
                `L ${end.x} ${end.y}`
            ].join(' ');
        }
    } else {
        const height = Math.abs(dy);
        const direction = dy > 0 ? 1 : -1;
        const bulgeWidth = props.tabSize * edgeType;
        const neckWidth = props.neckSize * edgeType;
        
        const anchorDistance = (height - props.tabWidth) / 2;
        
        let isTopSide = start.y < end.y;
        // If we're on the bottom side of the edge, swap A and C
        let [xA, xC] = isTopSide ? 
            [props.offsetA, props.offsetC] : 
            [props.offsetC, props.offsetA];
        xA *= edgeType;
        xC *= edgeType;

        // First anchor point (A)
        const A = createPoint(
            start.x + xA,
            start.y + (anchorDistance * direction)
        );
        
        // Second anchor point (C)
        const C = createPoint(
            start.x + xC,
            end.y - (anchorDistance * direction)
        );

        // Neck points (N1 and N2)
        const n1 = createPoint(
            start.x + neckWidth,
            A.y
        );
        const n2 = createPoint(
            n1.x,
            C.y
        );

        // Middle point (peak of the bulge)
        const midY = start.y + (height/2 * direction) + props.tabSway;
        const midX = n1.x + bulgeWidth; 

        // Control points for the main curve
        const curveOffset = 40 * props.tabCurvature;
        const cp1 = createPoint(
            midX,
            midY - (curveOffset * direction)
        );
        const cp2 = createPoint(
            midX,
            midY + (curveOffset * direction)
        );

        if (props.neckSmoothing === 0) {
            // No smoothing - straight lines to neck points
            return [
                `L ${A.x} ${A.y}`,
                `L ${n1.x} ${n1.y}`,
                `C ${cp1.x} ${cp1.y}, ${cp2.x} ${cp2.y}, ${n2.x} ${n2.y}`,
                `L ${C.x} ${C.y}`,
                `L ${end.x} ${end.y}`
            ].join(' ');
        } else {
            let XFractionBetweenBaselineAndNeck = 0.25;
            
            const firstControl = createPoint(
                A.x + (n1.x - A.x) * XFractionBetweenBaselineAndNeck,
                A.y + (props.neckSmoothing * direction)
            );
            
            const secondControl = createPoint(
                C.x + (n2.x - C.x) * XFractionBetweenBaselineAndNeck,
                C.y - (props.neckSmoothing * direction)
            );

            return [
                `L ${A.x} ${A.y}`,
                `Q ${firstControl.x} ${firstControl.y}, ${n1.x} ${n1.y}`,
                `C ${cp1.x} ${cp1.y}, ${cp2.x} ${cp2.y}, ${n2.x} ${n2.y}`,
                `Q ${secondControl.x} ${secondControl.y}, ${C.x} ${C.y}`,
                `L ${end.x} ${end.y}`
            ].join(' ');
        }
    }
}

function generatePiecePath(
    x: number, 
    y: number, 
    edges: { top: number; right: number; bottom: number; left: number },
    params: PuzzleParams,
    edgeProperties: (EdgeProperties | null)[]
): string {
    const points: Point[] = [
        { x: x * PIECE_SIZE, y: y * PIECE_SIZE },
        { x: (x + 1) * PIECE_SIZE, y: y * PIECE_SIZE },
        { x: (x + 1) * PIECE_SIZE, y: (y + 1) * PIECE_SIZE },
        { x: x * PIECE_SIZE, y: (y + 1) * PIECE_SIZE }
    ];

    const edgeTypes = [edges.top, edges.right, edges.bottom, edges.left];
    let path = `M ${points[0].x} ${points[0].y}`;

    for (let i = 0; i < 4; i++) {
        const start = points[i];
        const end = points[(i + 1) % 4];
        // Use the shared edge properties if they exist, otherwise use default params
        const props = edgeProperties[i] || params;
        path += ' ' + createEdgePath(start, end, edgeTypes[i], params, props);
    }

    path += ' Z';
    return path;
}

interface EdgeInfo {
    type: number;  // 1 for tab, -1 for indent
    properties: EdgeProperties;
}

export function generatePuzzlePieces(params: PuzzleParams): PuzzlePiece[] {
    const pieces: PuzzlePiece[] = [];
    const edgeMatrix: EdgeInfo[][] = [];
    const random = new Random(params.seed);
    // Initialize edge matrix with random tab/indent assignments and properties
    for (let y = 0; y <= params.piecesDown; y++) {
        edgeMatrix[y] = [];
        for (let x = 0; x <= params.piecesAcross; x++) {
            edgeMatrix[y][x] = {
                type: randomEdge(random),
                properties: generateEdgeProperties(random, params)
            };
        }
    }

    // Generate pieces
    for (let y = 0; y < params.piecesDown; y++) {
        for (let x = 0; x < params.piecesAcross; x++) {
            const edges = {
                top: y === 0 ? 0 : -edgeMatrix[y][x].type,
                right: x === params.piecesAcross - 1 ? 0 : -edgeMatrix[y][x + 1].type,
                bottom: y === params.piecesDown - 1 ? 0 : -edgeMatrix[y + 1][x].type,
                left: x === 0 ? 0 : -edgeMatrix[y][x].type
            };

            const edgeProperties = [
                y === 0 ? null : edgeMatrix[y][x].properties,
                x === params.piecesAcross - 1 ? null : edgeMatrix[y][x + 1].properties,
                y === params.piecesDown - 1 ? null : edgeMatrix[y + 1][x].properties,
                x === 0 ? null : edgeMatrix[y][x].properties
            ];

            pieces.push({
                id: `piece-${x}-${y}`,
                x,
                y,
                svgPath: generatePiecePath(x, y, edges, params, edgeProperties),
                edges
            });
        }
    }

    return pieces;
}
