export interface ControlConfig {
    min: number;
    max: number;
    step: number;
    defaultRange: {
        min: number;
        max: number;
    };
}

export const PARAMETER_CONFIGS = {
    tabSize: {
        min: 10,
        max: 40,
        step: 1,
        defaultRange: { min: 23, max: 26 }
    },
    tabWidth: {
        min: 10,
        max: 40,
        step: 1,
        defaultRange: { min: 18, max: 23 }
    },
    tabCurvature: {
        min: 0.5,
        max: 3.0,
        step: 0.1,
        defaultRange: { min: 0.8, max: 1.2 }
    },
    neckSize: {
        min: 1,
        max: 20,
        step: 1,
        defaultRange: { min: 3, max: 8 }
    },
    neckSmoothing: {
        min: 1,
        max: 10,
        step: 1,
        defaultRange: { min: 2, max: 5 }
    },
    offset: {
        min: -10,
        max: 10,
        step: 1,
        defaultRange: { min: -4, max: 4 }
    },
    tabSway: {
        min: -20,
        max: 20,
        step: 1,
        defaultRange: { min: -10, max: 10 }
    }
} as const;

export const generateRandomSeed = () => Math.random().toString(36).substring(2, 15);

export const PRESET_CONFIGS = {
    "Classic": {
        dimensions: { width: 1, height: 1 },
        piecesAcross: 4,
        piecesDown: 4,
        tabSize: PARAMETER_CONFIGS.tabSize.defaultRange,
        tabWidth: PARAMETER_CONFIGS.tabWidth.defaultRange,
        tabCurvature: PARAMETER_CONFIGS.tabCurvature.defaultRange,
        neckSize: PARAMETER_CONFIGS.neckSize.defaultRange,
        neckSmoothing: PARAMETER_CONFIGS.neckSmoothing.defaultRange,
        offset: PARAMETER_CONFIGS.offset.defaultRange,
        tabSway: PARAMETER_CONFIGS.tabSway.defaultRange,
        seed: generateRandomSeed(),
        debug: false
    },
    "Modern": {
        dimensions: { width: 1, height: 1 },
        piecesAcross: 4,
        piecesDown: 4,
        tabSize: { min: 20, max: 28 },
        tabWidth: { min: 18, max: 25 },
        tabCurvature: { min: 1.2, max: 1.8 },
        neckSize: { min: 4, max: 10 },
        neckSmoothing: { min: 2, max: 8 },
        offset: { min: -4, max: 4 },
        tabSway: { min: -10, max: 10 },
        seed: generateRandomSeed(),
        debug: false
    }
} as const; 