import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import Postmark from "../../assets/images/mark.png";
import SnowSketch from "../SnowSketch";
import { Letter } from "../../data/letters";
import { useSearchParams } from "react-router-dom";

type PageState = "mailbox" | "envelope" | "letter";

interface MailboxProps {
    letter: Letter;
}

export default function Mailbox({ letter }: MailboxProps) {
    const [searchParams] = useSearchParams();
    const [state, setState] = useState<PageState>(searchParams.get("view") === "true" ? "letter" : "mailbox");

    return (
        <div>
            <div className="absolute w-screen h-screen">
                <SnowSketch />
            </div>
            <div className="relative h-screen">
                <div className="relative flex flex-col items-center justify-center h-full">
                    <motion.div
                        initial={{ translateY: 50, opacity: 0 }}
                        animate={{
                            translateY: 0,
                            opacity: 1,
                            transition: {
                                transateY: {
                                    duration: 3,
                                    type: "spring",
                                    damping: 12,
                                },
                                opacity: {
                                    duration: 1,
                                },
                            },
                        }}
                        className="absolute h-24 w-60">
                        <div className="absolute w-60 pl-[100px] rounded-t-3xl py-4 pr-2 bg-gradient-to-b from-pink-500 to-red-500 h-full flex items-center">
                            <h1 className="mb-2">kelly's mailbox</h1>
                        </div>
                        <div className="absolute w-[70px] left-1.5 top-1.5 bottom-1.5 rounded-t-2xl bg-black"></div>
                        <div className="absolute bg-red-500 -bottom-1 -left-[60px] w-[140px] h-[10px] rounded-full"></div>
                        <div onClick={() => { setState("letter") }} className="cursor-pointer absolute bg-white bottom-2 left-[16px] w-[60px] h-[40px] rounded-l-sm text-xs font-semibold font-sans flex items-center justify-center pl-1 -rotate-3"><div>from bf</div></div>
                        <div className="w-7 h-32 ml-32 mt-[80px] bg-gradient-to-r from-amber-700 to-amber-900"></div>
                    </motion.div>

                    <motion.div
                        initial={{ translateY: 50, opacity: 0 }}
                        animate={{
                            translateY: 0,
                            opacity: 1,
                            transition: {
                                transateY: {
                                    duration: 3,
                                    delay: 1.5,
                                    type: "spring",
                                    damping: 12,
                                },
                                opacity: {
                                    duration: 1,
                                    delay: 1.5,
                                },
                            },
                        }}
                        className="relative">
                        <div className="text-white font-medium text-xl">gf's got mail ❤️</div>
                    </motion.div>
                    <motion.div
                        initial={{ translateY: 50, opacity: 0 }}
                        animate={{
                            translateY: 0,
                            opacity: 1,
                            transition: {
                                transateY: {
                                    duration: 3,
                                    delay: 5,
                                    type: "spring",
                                    damping: 12,
                                },
                                opacity: {
                                    duration: 1,
                                    delay: 5,
                                },
                            },
                        }}
                        className="relative">
                        <div className="text-white font-medium mt-2">(click on the letter)</div>
                    </motion.div>
                    <div className="h-60" />
                </div>
                {state === "letter" && (
                    <div onClick={() => { setState("mailbox") }} className="cursor-pointer absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center h-full">
                        <Envelope message={letter} initiallyOpen={searchParams.get("view") === "true"} />
                    </div>
                )}
            </div>
        </div>
    );
}

type Message = Letter;

const DURATION = 0.4;

function EnvelopeContents({ message }: { message: Message }) {
    return (
        <>
            <div className="row-span-1 flex justify-between items-center px-14">
                <div className="">
                    <p className={`font-extralight text-xs`}>From</p>
                    <p className={`font-semibold text-2xl`}>{message.fromEmail}</p>
                </div>
                <div className="">
                    <img src={Postmark} alt="Postage Stamp" width={100} height={64} />
                </div>
            </div>
            <div className="row-span-1 px-14 flex flex-col justify-center items-center space-y-1">
                <p className={`font-medium text-4xl text-center`}>{message.subject}</p>
                <p className={`font-normal text-sm text-gray-500`}>with love</p>
            </div>
        </>
    )
}

function Envelope({ message, initiallyOpen = false }: { message: Message, initiallyOpen?: boolean }) {
    const [isOpening, setIsOpening] = useState(initiallyOpen);

    const direction = 'above'

    const yKeyframes = {
        'above': [-30, -120, 0],
        'below': [24, 100, 0]
    };

    const isFlipped = true

    return (
        <div className="relative w-full h-full pl-4 overflow-clip max-h-screen">
            <motion.div
                className="absolute top-0 w-full left-0 h-full flex items-center justify-center"
                initial={{ y: yKeyframes[direction][0], zIndex: 20, scale: 0.96, opacity: 1 }}
                animate={{
                    y: yKeyframes[direction],
                    scale: [0.96, 1],
                }}
                transition={{
                    duration: DURATION,
                    type: "spring",
                    stiffness: 160,
                    damping: 20,
                }}
            >
                {!isOpening && (
                    <motion.div
                        onClick={(e) => { e.stopPropagation() }}
                        className="relative cursor-default w-[860px] h-[500px] bg-white rounded-xl"
                        style={{ 
                            transformStyle: "preserve-3d", 
                            perspective: "2000px",
                            filter: "drop-shadow(0px 4.49515px 4.49515px rgba(0, 0, 0, 0.25))" 
                        }}
                        animate={{
                            rotateY: isFlipped ? 180 : 0,
                            rotateX: isFlipped ? [-3, -3, 0] : 0,
                            transition: {
                                duration: 1,
                                delay: 2.5,
                                rotateX: {
                                    duration: 1.5,
                                    times: [0, 0.5, 1]
                                }
                            },
                        }}
                    >
                        <motion.div
                            className="grid grid-rows-3 h-full w-full absolute"
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: [0, 1, 1]
                            }}
                            transition={{
                                duration: DURATION,
                                type: "spring",
                                stiffness: 160,
                                damping: 20,
                            }}
                        >
                            <EnvelopeContents message={message} />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: 0.1,
                                    delay: 2.8
                                },
                            }}
                            className="cursor-pointer absolute bg-white top-0 left-0 h-full w-full rounded-xl"
                            onClick={(e) => {
                                if (Number(window.getComputedStyle(e.target as HTMLElement).opacity) < 0.99) {
                                    return
                                }
                                setIsOpening(true)
                            }}
                            style={{ transform: "rotateY(180deg)" }}>
                            <div className="absolute w-[53.8%] left-1 bg-red-600 h-[6px] rounded-full rotate-[22deg] origin-top-left" />
                            <div className="absolute w-[53.8%] right-1 bg-red-600 h-[6px] rounded-full -rotate-[22deg] origin-top-right" />
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: 1,
                                        delay: 5
                                    },
                                }} 
                                className="pointer-events-none flex items-center justify-center h-full"
                            >
                                <div className="mb-16">open me</div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                )}

                {isOpening && (
                    <motion.div
                        onClick={(e) => { e.stopPropagation() }}
                        className="relative cursor-default w-[860px] h-[500px] bg-white rounded-xl"
                        style={{ filter: "drop-shadow(0px 4.49515px 4.49515px rgba(0, 0, 0, 0.25))" }}
                    >
                        <motion.div
                            className="absolute bg-white top-0 left-0 h-full w-full rounded-xl"
                        >
                            <div className="absolute w-[53.8%] left-1 bg-white h-[400px] -rotate-[22deg] origin-top-left" />
                            <div className="absolute w-[53.8%] right-1 bg-white h-[400px] rotate-[22deg] origin-top-right" />
                            <div className="absolute w-[53.8%] left-1 bg-red-600 h-[6px] rounded-full -rotate-[22deg] origin-top-left" />
                            <div className="absolute w-[53.8%] right-1  bg-red-600 h-[6px] rounded-full rotate-[22deg] origin-top-right" />

                            <div className="absolute w-[53.8%] left-1 bg-gray-300 h-[3px] rounded-full rotate-[22deg] origin-top-left" />
                            <div className="absolute w-[53.8%] right-1  bg-gray-300 h-[3px] rounded-full -rotate-[22deg] origin-top-right" />

                            <div className="absolute w-full bg-gray-400 h-1 top-1" />

                            <motion.div
                                initial={{ opacity: 0, translateY: 400, scale: 0.8, rotateX: 45 }}
                                animate={{
                                    opacity: 1,
                                    translateY: 0,
                                    scale: 1,
                                    rotateX: 0,
                                    transition: {
                                        duration: 1.2,
                                        delay: 0.5,
                                        opacity: { duration: 0.4, delay: 0.5 },
                                        translateY: { type: "spring", damping: 20 },
                                        rotateX: { duration: 1.0 },
                                    },
                                }}
                                style={{ 
                                    filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25))",
                                    transformOrigin: "top",
                                    perspective: 1000,
                                }}
                                className="absolute bg-white h-full left-6 right-6 shadow-2xl -top-10 py-10 rounded-t-sm">
                                <div className="relative h-full w-full">
                                    <div className="overflow-y-scroll h-full w-full px-14">
                                        <div className="h-6" />
                                        <div className="flex flex-col">
                                            {message.body.map(b => <div key={b}>{b}&nbsp;</div>)}
                                        </div>
                                        {message.link && (
                                            <a href={message.link.href} className="underline text-blue-500">{message.link.text}</a>
                                        )}
                                        <div className="h-16" />
                                    </div>
                                    <div className="absolute top-0 left-0 right-4 h-8 bg-gradient-to-b from-white to-transparent pointer-events-none"></div>
                                    <div className="absolute bottom-0 left-0 right-4 h-8 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
                                </div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                )}
            </motion.div>
        </div>
    )
}