import { PuzzlePiece } from "../components/PuzzleControls";
import { Direction, PiecePosition, calculateSnappedPosition, getEdgeInfo, getNeighborsFast } from "./pieceDistance";
import { getPiecesGrid } from "./pieceGrid";

export type PuzzleSection = Set<string>; // Set of piece IDs

export interface MergeOpportunity {
  piece: PuzzlePiece;
  neighborPiece: PuzzlePiece;
  direction: Direction;
  distance: number;
  targetPosition: PiecePosition;
}

// Use Map for faster lookups
const sectionCache = new Map<string, PuzzleSection>();

export function findSection(sections: PuzzleSection[], pieceId: string): PuzzleSection | undefined {
  // Check cache first
  const cached = sectionCache.get(pieceId);
  if (cached) return cached;

  const section = sections.find(section => section.has(pieceId));
  if (section) {
    sectionCache.set(pieceId, section);
  }
  return section;
}

export function getSectionPieces(sections: PuzzleSection[], pieceId: string): string[] {
  const section = findSection(sections, pieceId);
  return section ? Array.from(section) : [pieceId];
}

export function moveSectionPieces(
  piecePositions: Record<string, PiecePosition>,
  sectionPieces: string[],
  deltaX: number,
  deltaY: number
): Record<string, PiecePosition> {
  const newPositions = { ...piecePositions };
  for (const pieceId of sectionPieces) {
    const pos = piecePositions[pieceId];
    newPositions[pieceId] = {
      x: pos.x + deltaX,
      y: pos.y + deltaY
    };
  }
  return newPositions;
}

export function findMergeOpportunities(
  allPieces: PuzzlePiece[],
  currentSectionPieceIds: string[],
  pieceIdToPosition: Record<string, PiecePosition>,
  pieceSize: number,
  snapThreshold: number,
  allSections: PuzzleSection[]
): {
  mergeOpportunities: MergeOpportunity[];
  sectionsToMerge: PuzzleSection[];
  allDistances: MergeOpportunity[];
} {
  const sectionsToMerge: PuzzleSection[] = [];
  const mergeOpportunities: MergeOpportunity[] = [];
  const allDistances: MergeOpportunity[] = [];
  const sectionPieceSet = new Set(currentSectionPieceIds);

  // Create the grid once
  const piecesGrid = getPiecesGrid(allPieces);

  // Create a map of piece id to piece for faster lookups
  const pieceMap = new Map(allPieces.map((p) => [p.id, p]));

  for (const pieceId of currentSectionPieceIds) {
    const piece = pieceMap.get(pieceId);
    if (!piece) continue;

    const neighbors = getNeighborsFast(piece, piecesGrid);

    for (const { neighbor, direction } of neighbors) {
      // Skip if neighbor is already in our section
      if (sectionPieceSet.has(neighbor.id)) continue;

      const edgeInfo = getEdgeInfo(
        pieceIdToPosition[pieceId],
        pieceIdToPosition[neighbor.id],
        direction,
        pieceSize
      );
      const opportunity = {
        piece,
        neighborPiece: neighbor,
        direction,
        distance: edgeInfo.distance,
        targetPosition: edgeInfo.targetPosition,
      };
      allDistances.push(opportunity);

      if (edgeInfo.distance < snapThreshold) {
        mergeOpportunities.push(opportunity);
        const neighborSection = findSection(allSections, neighbor.id);
        if (neighborSection && !sectionsToMerge.includes(neighborSection)) {
          sectionsToMerge.push(neighborSection);
        }
      }
    }
  }

  // Sort all distances by distance
  allDistances.sort((a, b) => a.distance - b.distance);

  return { mergeOpportunities, sectionsToMerge, allDistances };
}

export function mergeSections(
  allSections: PuzzleSection[],
  sectionsToMerge: PuzzleSection[]
): PuzzleSection[] {
  if (sectionsToMerge.length === 0) return allSections;

  // Clear the cache since sections are changing
  sectionCache.clear();

  // Create a new merged section with all pieces from all sections
  const mergedSection = new Set<string>();
  for (const section of sectionsToMerge) {
    Array.from(section).forEach(pieceId => mergedSection.add(pieceId));
  }

  // Return all sections that weren't merged, plus the new merged section
  return allSections
    .filter(section => !sectionsToMerge.includes(section))
    .concat([mergedSection]);
}

export function snapSectionPieces(
  piecePositions: Record<string, PiecePosition>,
  sectionPieces: string[],
  mergeOpportunities: MergeOpportunity[],
  pieceSize: number
): Record<string, PiecePosition> {
  if (mergeOpportunities.length === 0) return piecePositions;

  const newPositions = { ...piecePositions };
  const { piece, neighborPiece, direction } = mergeOpportunities[0];

  // Calculate snap position once
  const snappedPosition = calculateSnappedPosition(
    direction as any,
    piecePositions[neighborPiece.id],
    pieceSize
  );

  // Calculate offset once
  const offsetX = snappedPosition.x - piecePositions[piece.id].x;
  const offsetY = snappedPosition.y - piecePositions[piece.id].y;

  // Apply offset to all pieces in the section
  for (const pieceId of sectionPieces) {
    const pos = piecePositions[pieceId];
    newPositions[pieceId] = {
      x: pos.x + offsetX,
      y: pos.y + offsetY
    };
  }

  return newPositions;
}

function calculateSectionCentroid(sectionPieces: string[], piecePositions: Record<string, PiecePosition>): PiecePosition {
  const positions = sectionPieces.map(id => piecePositions[id]);
  return {
    x: positions.reduce((sum, pos) => sum + pos.x, 0) / positions.length,
    y: positions.reduce((sum, pos) => sum + pos.y, 0) / positions.length
  };
}

function doSectionsOverlap(
  section1Pieces: string[],
  section2Pieces: string[],
  piecePositions: Record<string, PiecePosition>,
  pieceSize: number
): boolean {
  // Get bounding boxes
  const s1Positions = section1Pieces.map(id => piecePositions[id]);
  const s2Positions = section2Pieces.map(id => piecePositions[id]);

  const s1Left = Math.min(...s1Positions.map(p => p.x));
  const s1Right = Math.max(...s1Positions.map(p => p.x)) + pieceSize;
  const s1Top = Math.min(...s1Positions.map(p => p.y));
  const s1Bottom = Math.max(...s1Positions.map(p => p.y)) + pieceSize;

  const s2Left = Math.min(...s2Positions.map(p => p.x));
  const s2Right = Math.max(...s2Positions.map(p => p.x)) + pieceSize;
  const s2Top = Math.min(...s2Positions.map(p => p.y));
  const s2Bottom = Math.max(...s2Positions.map(p => p.y)) + pieceSize;

  // Calculate overlap dimensions
  const overlapWidth = Math.min(s1Right, s2Right) - Math.max(s1Left, s2Left);
  const overlapHeight = Math.min(s1Bottom, s2Bottom) - Math.max(s1Top, s2Top);

  if (overlapWidth <= 0 || overlapHeight <= 0) return false;

  // Calculate overlap area as a percentage of the smaller section's area
  const overlapArea = overlapWidth * overlapHeight;
  const s1Area = (s1Right - s1Left) * (s1Bottom - s1Top);
  const s2Area = (s2Right - s2Left) * (s2Bottom - s2Top);
  const smallerArea = Math.min(s1Area, s2Area);
  
  const overlapPercentage = overlapArea / smallerArea;
  
  const OVERLAP_THRESHOLD = 0.10;

  return overlapPercentage > OVERLAP_THRESHOLD && section1Pieces.length <= 4 && section2Pieces.length <= 4;
}

export function repelOverlappingSections(
  droppedSectionPieces: string[],
  allSections: PuzzleSection[],
  piecePositions: Record<string, PiecePosition>,
  pieceSize: number
): Record<string, PiecePosition> {
  const FIXED_REPULSION_DISTANCE = pieceSize * 0.3; // Fixed distance to push
  const newPositions = { ...piecePositions };
  const droppedCentroid = calculateSectionCentroid(droppedSectionPieces, piecePositions);

  // Check each section for overlap
  allSections.forEach(section => {
    const sectionPieces = Array.from(section);
    
    // Skip if this is the dropped section
    if (sectionPieces.some(id => droppedSectionPieces.includes(id))) return;

    // Check for overlap
    if (!doSectionsOverlap(droppedSectionPieces, sectionPieces, piecePositions, pieceSize)) return;

    // Calculate direction vector
    const sectionCentroid = calculateSectionCentroid(sectionPieces, piecePositions);
    const vectorX = sectionCentroid.x - droppedCentroid.x;
    const vectorY = sectionCentroid.y - droppedCentroid.y;

    // Normalize the vector (make it a unit vector)
    const magnitude = Math.sqrt(vectorX * vectorX + vectorY * vectorY);
    if (magnitude === 0) return;

    // Apply fixed distance in the direction of the unit vector
    const pushX = (vectorX / magnitude) * FIXED_REPULSION_DISTANCE;
    const pushY = (vectorY / magnitude) * FIXED_REPULSION_DISTANCE;

    // Apply the push to all pieces in the overlapping section
    sectionPieces.forEach(pieceId => {
      newPositions[pieceId] = {
        x: piecePositions[pieceId].x + pushX,
        y: piecePositions[pieceId].y + pushY
      };
    });
  });

  return newPositions;
} 