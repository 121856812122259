import React, { useEffect, useState, useRef } from 'react';
import { PuzzleParams } from './components/PuzzleControls';
import { generatePuzzlePieces } from './utils/puzzleGenerator';
import { decodePuzzle } from './components/PuzzleExport';
import PuzzleBoard from './components/PuzzleBoard';
import puzzleBg from '../../assets/images/puzzlebg.png';
import AudioControls from './components/AudioControls';
import PuzzleSelector from './components/PuzzleSelector';
import CompletionOverlay from './components/CompletionOverlay';
import confetti from 'canvas-confetti';
import { OpeningState } from './types';
import { loadPuzzleData, savePuzzleData, clearPuzzleData, getPuzzleData, markPuzzleStarted, hasPuzzleStarted } from './utils/puzzleUtils';
import BoxOpening, { BoxOpeningHandle } from './components/BoxOpening';

interface LoadedPuzzle {
  params: PuzzleParams;
  image: HTMLImageElement;
}

export default function Bezzel() {
  const [puzzle, setPuzzle] = useState<LoadedPuzzle>();
  const [pieces, setPieces] = useState<any[]>([]);
  const [error, setError] = useState<string>();
  const [isComplete, setIsComplete] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [openingState, setOpeningState] = useState<OpeningState>();

  const boxOpeningRef = useRef<BoxOpeningHandle>(null);

  const loadPuzzle = async () => {
    const puzzleData = getPuzzleData();
    if (!puzzleData) return;

    try {
      const loadedPuzzle = await decodePuzzle(JSON.parse(puzzleData));
      setPuzzle(loadedPuzzle);
      setPieces(generatePuzzlePieces(loadedPuzzle.params));
      window.history.pushState({ inPuzzle: true }, '');
    } catch (err) {
      console.error('Failed to load puzzle:', err);
      setError('Failed to load puzzle. Please try creating a new one.');
    }
  };

  const handleReturn = () => {
    // If we're in an active puzzle that's not complete, ask for confirmation
    if (puzzle && !isComplete) {
      const shouldReturn = window.confirm('Discard current puzzle and return to collection?');
      if (!shouldReturn) {
        return;
      }
    }

    // At this point, we're either:
    // 1. In the opening animation
    // 2. In a completed puzzle
    // 3. Got confirmation to leave an active puzzle
    clearPuzzleData();
    setPuzzle(undefined);
    setPieces([]);
    setIsComplete(false);
    setOpeningState(undefined);
    window.history.back();
  };

  const handleBoxReturn = () => {
    clearPuzzleData();
    setPuzzle(undefined);
    setPieces([]);
    setIsComplete(false);
    setOpeningState(undefined);
    window.history.back();
  };

  const handlePuzzleComplete = () => {
    setIsComplete(true);

    // Create a more elaborate snow-themed confetti celebration
    const duration = 2 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    const randomInRange = (min: number, max: number) => Math.random() * (max - min) + min;

    const interval: NodeJS.Timer = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50;

      // Snowflake confetti from various angles
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.2, 0.8), y: randomInRange(0.2, 0.4) },
        colors: ['#ffffff', '#d9d9d9'],
        shapes: ['circle'],
        gravity: 1.6,
        scalar: 1.2,
        drift: randomInRange(-0.5, 0.5)
      });
    }, 250);
  };

  const handleStartPuzzle = async () => {
    if (!openingState) return;
    
    try {
      const puzzleData = await loadPuzzleData(openingState.preset);
      savePuzzleData(puzzleData);
      markPuzzleStarted();
      await loadPuzzle();
    } catch (err) {
      console.error('Failed to start puzzle:', err);
      setError('Failed to load puzzle. Please try again.');
    }
  };

  useEffect(() => {
    loadPuzzle();

    const handlePopState = () => {
      clearPuzzleData();
      setPuzzle(undefined);
      setPieces([]);
      setIsComplete(false);
      setOpeningState(undefined);
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  const backgroundStyle = {
    backgroundImage: `url(${puzzleBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh'
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center" style={backgroundStyle}>
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center">
          <h1 className="text-2xl font-bold mb-4">Oops!</h1>
          <p className="text-gray-600 mb-4">{error}</p>
          <a 
            href="/bezzel/cutter" 
            className="inline-block bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Go to Puzzle Cutter
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4" style={backgroundStyle}>
      <div className="absolute top-14 left-4 w-full">
        <AudioControls />
      </div>

      {puzzle ? (
        <>
          <div className="w-full min-h-screen flex justify-center items-center perspective-[2000px]">
            <PuzzleBoard
              pieces={pieces}
              width={1200}
              height={800}
              image={puzzle.image}
              onComplete={handlePuzzleComplete}
              isFlipped={isFlipped}
            />
          </div>
          {isComplete && (
            <CompletionOverlay 
              isFlipped={isFlipped} 
              onFlip={() => setIsFlipped(!isFlipped)} 
            />
          )}
        </>
      ) : openingState ? (
        <BoxOpening 
          ref={boxOpeningRef}
          openingState={openingState} 
          onStart={handleStartPuzzle} 
          onReturn={handleBoxReturn}
        />
      ) : (
        <PuzzleSelector 
          onPuzzleSelected={loadPuzzle} 
          onPuzzleBoxClick={(preset, position) => setOpeningState({ preset, position })}
        />
      )}

      <h1 
        onClick={handleReturn}
        className="absolute top-4 text-4xl font-bold mb-4 text-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.8)] bg-black/5 hover:bg-black/10 inline-block px-6 py-2 rounded-lg cursor-pointer transition-colors duration-200"
      >
        Bezzel
      </h1>
      {(puzzle || openingState) && (
        <button
          onClick={handleReturn}
          className={`absolute top-4 right-4 backdrop-blur-md text-white py-2 px-4 rounded-lg transition-colors duration-200 ${
            isComplete
              ? "bg-black/20 hover:bg-black/30"
              : "bg-black/20 hover:bg-black/30"
          }`}
        >
          {isComplete ? "Back to Collection" : "Return to Collection"}
        </button>
      )}
    </div>
  );
}