import prettyMs from "pretty-ms";
import { useEffect, useState } from "react";
import SnowSketch from "./SnowSketch";
import Wreath from "../assets/images/wreath.png";
import { motion } from "framer-motion";

function Snow() {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const handle = setInterval(() => {
      setNow(new Date());
    }, 50);

    return () => {
      clearInterval(handle);
    };
  }, []);

  const target = new Date("December 25 2025");
  const diffMillis = target.getTime() - now.getTime();
  const hoursUntil = Math.ceil(diffMillis / (1000 * 60 * 60));
  const daysUntil = Math.ceil(diffMillis / (1000 * 60 * 60 * 24));
  const time = prettyMs(diffMillis, { secondsDecimalDigits: 0 });
  const times = time.split(" ");

  const keyframes = {
    WREATH_ENDS_AT: 0.7,
    EXPAND_STARTS_AT: 1,
    PRIMARY_FADE_IN_STARTS_AT: 1.4,
    EXPAND_ENDS_AT: 2,
    PRIMARY_FADE_IN_ENDS_AT: 2.4,
  };

  return (
    <div className="relative bg-zinc-900 w-screen h-screen">
      <div className="absolute w-screen h-screen">
        <SnowSketch />
      </div>
      <div className="absolute flex flex-col w-screen h-screen items-center justify-center">
        <div className="relative px-16 py-8">
          <div className="absolute -bottom-4 right-2 -rotate-90">
            <motion.img
              initial={{ scale: 0.6 }}
              animate={{
                scale: 1,
                transition: {
                  duration: keyframes.WREATH_ENDS_AT,
                  type: "spring",
                  damping: 10,
                },
              }}
              src={Wreath}
              width="60"
              className=""
            />
          </div>{" "}
          <div className="absolute -top-4 left-2 rotate-90">
            <motion.img
              initial={{ scale: 0.7 }}
              animate={{
                scale: 1,
                transition: {
                  duration: keyframes.WREATH_ENDS_AT,
                  type: "spring",
                  damping: 11,
                },
              }}
              src={Wreath}
              width="60"
            />
          </div>
          <div className="relative flex flex-col items-center">
            <motion.div
              initial={{ height: 0, width: 150 }}
              animate={{
                height: 53,
                width: window.innerWidth < 640 ? 230 : 303,
                transition: {
                  width: {
                    delay: keyframes.EXPAND_STARTS_AT,
                    duration:
                      keyframes.EXPAND_ENDS_AT - keyframes.EXPAND_STARTS_AT,
                    ease: "easeInOut",
                  },
                  height: {
                    delay: keyframes.EXPAND_STARTS_AT,
                    duration:
                      keyframes.EXPAND_ENDS_AT - keyframes.EXPAND_STARTS_AT,
                    ease: "easeInOut",
                  },
                },
              }}
            >
              <motion.div
                animate={{
                  top: [-40, 0],
                  opacity: [0, 0.3, 1],
                  transition: {
                    top: {
                      delay: keyframes.EXPAND_STARTS_AT,
                      duration:
                        keyframes.EXPAND_ENDS_AT - keyframes.EXPAND_STARTS_AT,
                      ease: "easeInOut",
                    },
                    opacity: {
                      delay: keyframes.PRIMARY_FADE_IN_STARTS_AT,
                      duration:
                        keyframes.PRIMARY_FADE_IN_ENDS_AT -
                        keyframes.PRIMARY_FADE_IN_STARTS_AT,
                      ease: "easeInOut",
                    },
                  },
                }}
                className="absolute -left-10 -right-10 flex flex-col items-center"
              >
                {daysUntil < 1 ? (
                  <p className="text-3xl sm:text-4xl text-red-400">
                    <span className="christmas-card">Merry Kellymas!!</span>
                  </p>
                ) :
                  daysUntil === 1 && hoursUntil <= 10 ? (
                    <p className="text-3xl sm:text-4xl text-red-400">
                      <span className="christmas-card">Merry Kellymas Eve!! 🎄</span>
                    </p>
                  ) : (<p className="text-3xl sm:text-4xl text-red-400">
                    <span>{daysUntil}</span>{" "}
                    <span className="christmas-card">{daysUntil === 1 ? "Day" : "Days"} Until Kellymas</span>
                  </p>)}

              </motion.div>
            </motion.div>
            {daysUntil >= 0 ? (
              <div className="flex">
                {times.map((t, index) => (
                  <p
                    key={index}
                    className={`mx-1 text-xl font-normal ${index % 2 === 0 ? "text-green-400" : "text-red-400"
                      }`}
                  >
                    {t}{" "}
                  </p>
                ))}
              </div>
            ) : (
              <div>❤️🥰🎄🎅💗</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Snow;
