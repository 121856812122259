import { PuzzlePiece } from "../components/PuzzleControls";

// Cache for the pieces grid
let piecesGridCache: PuzzlePiece[][] | null = null;
let lastPiecesHash: string | null = null;

function getPiecesHash(pieces: PuzzlePiece[]): string {
  if (pieces.length === 0) return '';
  return `${pieces.length}:${pieces[0].svgPath}`;
}

export function getPiecesGrid(pieces: PuzzlePiece[]): PuzzlePiece[][] {
  const piecesHash = getPiecesHash(pieces);
  
  // Return cached grid if pieces haven't changed
  if (piecesGridCache && piecesHash === lastPiecesHash) {
    return piecesGridCache;
  }

  const maxY = Math.max(...pieces.map(p => p.y)) + 1;
  const maxX = Math.max(...pieces.map(p => p.x)) + 1;
  
  // Initialize empty grid
  const grid: PuzzlePiece[][] = Array(maxY).fill(null).map(() => Array(maxX).fill(null));
  
  // Place pieces in their correct positions
  pieces.forEach(piece => {
    grid[piece.y][piece.x] = piece;
  });

  // Cache the new grid
  piecesGridCache = grid;
  lastPiecesHash = piecesHash;
  
  return grid;
} 