import Sketch from "react-p5";
import p5Types from "p5"; //Import this for typechecking and intellisense
import { random } from "../core/utils";

const particles: {
  x: number;
  y: number;
  size: number;
  spd: number;
  acc: number;
  stopHeight: number;
}[] = [];

const trees: {
  x: number;
  yOffset: number;
  imgIndex: number;
}[] = [];

const treeSizePx = 70;
let wind = 0;
let windAcc = 0;
let currentTime = 0;
const snowiness = Math.ceil(Math.random() * 6);

const groundTop_ypos = 100;
let treeImgs: p5Types.Image[] = [];
let santaImg: p5Types.Image;

function SnowSketch() {
  const preload = (p5: p5Types) => {
    for (let i = 0; i < 5; i++) {
      treeImgs.push(p5.loadImage(`/tree/t${i}.png`));
    }
    santaImg = p5.loadImage('/santa/0.png')
  }

  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.frameRate(30);
    p5.createCanvas(window.innerWidth, window.innerHeight).parent(
      canvasParentRef
    );

    for (let x = 0; x < p5.width / treeSizePx; x++) {
      for (let y = 0; y < 3; y++) {
        if (Math.random() > 0.2) {
          trees.push({
            x: x * treeSizePx + (Math.random() - 0.5) * treeSizePx * 0.75,
            yOffset: groundTop_ypos / 3 * y + (Math.random() - 0.5) * groundTop_ypos / 6,
            imgIndex: Math.floor(Math.random() * treeImgs.length),
          })
        }
      }
    }
  };

  const draw = (p5: p5Types) => {
    if (p5.frameRate()) {
      currentTime += 1.0 / p5.frameRate();
    }

    p5.background(24, 24, 27); // bg-zinc-900

    trees.forEach(({ x, yOffset, imgIndex }) => {
      p5.image(treeImgs[imgIndex], x, p5.height - yOffset - treeSizePx, treeSizePx, treeSizePx)
    })

    const santaSize = 40;
    const yOffset = Math.cos(Math.PI * 2 * currentTime * 0.25) * 60;
    p5.image(santaImg, -100 + (p5.width + 200 + currentTime * 250) % (p5.width + 2100) + yOffset * 0.6, p5.height / 5 + yOffset, santaSize * 3, santaSize * 2)

    particles.forEach((particle) => {
      p5.noStroke();
      p5.ellipse(particle.x, particle.y, particle.size, particle.size);

      if (p5.height - particle.y > particle.stopHeight) {
        particle.y += particle.spd / p5.frameRate();
        particle.spd += particle.acc / p5.frameRate();
        particle.x += wind / Math.sqrt(particle.size);
      }
    });

    if (p5.frameRate()) {
      wind += windAcc / p5.frameRate();
      windAcc = Math.random() - 0.495;
      if (wind > 0.2 || wind < -0.2) {
        wind = 0;
      }
    }

    if (Math.random() < 0.12 + Math.sqrt(currentTime) * 0.1) {
      for (let i = 0; i < snowiness; i++) {
        const x = -100 + Math.random() * (p5.width + 100)
        const maxAugHeight = 40;
        const stopHeightAugment = maxAugHeight + Math.cos(Math.PI * 2 * x / (p5.width / 3)) * maxAugHeight
        particles.push({
          x,
          y: 0,
          size: random(3, 8),
          spd: random(30, 50),
          acc: 0,
          stopHeight: random(0, groundTop_ypos + stopHeightAugment * Math.min(1, Math.sqrt(currentTime) / 9)),
        });
      }
    }
  };

  return <Sketch preload={preload as any} setup={setup as any} draw={draw as any} />;
}

export default SnowSketch;
