import React from 'react';
import { PuzzlePreset } from '../puzzle-presets';

interface FrontOfBoxProps {
  preset: PuzzlePreset;
  index?: number;
  onImageLoad?: () => void;
  className?: string;
}

export default function FrontOfBox({ preset, index, onImageLoad, className = '' }: FrontOfBoxProps) {
  return (
    <div className={`relative bg-red-800 rounded-md shadow-2xl ${className}`}>
      {/* Image area with padding */}
      <div className="p-4">
        <div className="relative aspect-[4/3] rounded-sm overflow-hidden border-2 border-red-900/50 shadow-inner">
          <img
            src={preset.imageUrl}
            alt={`Puzzle ${index ? index + 1 : ''}`}
            className="w-full h-full object-cover"
            onLoad={onImageLoad}
          />
          {/* Image overlay for depth */}
          <div className="absolute inset-0 shadow-inner" />
        </div>
      </div>

      {/* Info bar */}
      <div className="px-4 pb-4 flex justify-between items-center">
        <div className="text-red-100 font-medium">
          {preset.piecesAcross}×{preset.piecesDown}
        </div>
        <div className="text-red-100 chunky text-2xl">Bezzel</div>
      </div>

      {/* Subtle highlight */}
      <div className="absolute inset-x-0 top-0 h-1/2 bg-gradient-to-b from-white/5 to-transparent rounded-t-md" />
    </div>
  );
} 