import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PiecePosition, calculateSnappedPosition, getOriginToMidpoint } from "../utils/pieceDistance";
import {
  PuzzleSection as PuzzleSectionType,
  findMergeOpportunities,
  findSection,
  getSectionPieces,
  mergeSections,
  repelOverlappingSections,
  MergeOpportunity
} from "../utils/sectionManagement";
import { PuzzlePiece as PuzzlePieceType } from "./PuzzleControls";
import { CELL_SIZE, TAB_PADDING, widthOfViewBox } from "./PuzzlePiece";
import PuzzleSection from "./PuzzleSection";

interface PuzzleBoardProps {
  pieces: PuzzlePieceType[];
  width: number;
  height: number;
  image?: HTMLImageElement;
  onComplete?: () => void;
  isFlipped?: boolean;
}

// Custom hook for piece position initialization
function useInitialPiecePositions(pieces: PuzzlePieceType[], width: number, height: number, maxX: number, maxY: number, padding: number) {
  return useMemo(() => {
    const positions: Record<string, PiecePosition> = {};

    // Calculate the exact center of the board
    const centerX = width / 2;
    const centerY = height / 2;

    // Calculate piece size the same way as in the component
    const finalPuzzleShapeWidth = width * 0.70;
    const finalPuzzleShapeHeight = height * 0.85;
    const basePieceSize = Math.min(finalPuzzleShapeWidth / maxX, finalPuzzleShapeHeight / maxY);
    const pieceSize = basePieceSize * 1.3;

    pieces.forEach((piece) => {
      positions[piece.id] = {
        x: centerX - (pieceSize / 2), // Offset by half a piece size
        y: centerY - (pieceSize / 2)  // Offset by half a piece size
      };
    });
    return positions;
  }, [pieces, width, height, maxX, maxY, padding]);
}

// Add new function to calculate final positions
function calculateFinalPositions(pieces: PuzzlePieceType[], width: number, height: number, maxX: number, maxY: number, padding: number) {
  const positions: Record<string, PiecePosition> = {};
  const indexMapping = Array.from({ length: pieces.length }, (_, index) => index);
  indexMapping.sort(() => Math.random() - 0.5);

  pieces.forEach((piece, index) => {
    const mappedIndex = indexMapping[index];
    const initialXIndexed = pieces[mappedIndex].x;
    const initialYIndexed = pieces[mappedIndex].y;
    positions[piece.id] = {
      x: (initialXIndexed + 0.5) * (width - 2 * padding) / maxX,
      y: (initialYIndexed + 0.5) * (height - 2 * padding) / maxY
    };
  });
  return positions;
}

// Custom hook for section management
function useSectionManagement(pieces: PuzzlePieceType[], onComplete?: () => void) {
  const [sections, setSections] = useState<PuzzleSectionType[]>(() => 
    pieces.map(piece => new Set([piece.id]))
  );

  useEffect(() => {
    if (sections.length === 1 && onComplete) {
      onComplete();
    }
  }, [sections, onComplete]);

  return { sections, setSections };
}

// Helper function to handle section merging
function handleSectionMerging(
  sectionPieces: string[],
  mergeOpportunities: Array<{ piece: PuzzlePieceType, neighborPiece: PuzzlePieceType, direction: string }>,
  currentSection: Set<string>,
  sections: PuzzleSectionType[],
  positions: Record<string, PiecePosition>,
  pieceSize: number
) {
  // TEMPORARY BUG FIX: ONLY EVER MERGE ONE SECTION AT A TIME
  mergeOpportunities = mergeOpportunities.slice(0, 1);

  let currentSections = sections;
  let currentPositions = positions;

  mergeOpportunities.forEach(({ piece, neighborPiece, direction }) => {
    const neighborSection = findSection(currentSections, neighborPiece.id);
    if (!neighborSection) return;

    currentSections = mergeSections(currentSections, [currentSection, neighborSection]);

    const snappedPosition = calculateSnappedPosition(
      direction as any,
      currentPositions[neighborPiece.id],
      pieceSize
    );

    const offsetX = snappedPosition.x - currentPositions[piece.id].x;
    const offsetY = snappedPosition.y - currentPositions[piece.id].y;

    currentPositions = { ...currentPositions };
    sectionPieces.forEach(pieceId => {
      currentPositions[pieceId] = {
        x: currentPositions[pieceId].x + offsetX,
        y: currentPositions[pieceId].y + offsetY
      };
    });
  });

  return { currentSections, currentPositions };
}

interface PuzzlePieceBackProps {
  piece: PuzzlePieceType;
  pieceSize: number;
  position: PiecePosition;
  sectionPosition: PiecePosition;
}

function PuzzlePieceBack({ piece, pieceSize, position, sectionPosition }: PuzzlePieceBackProps) {
  return (
    <div
      key={piece.id}
      className="absolute"
      style={{
        width: pieceSize,
        height: pieceSize,
        transform: `translate(${position.x - sectionPosition.x}px, ${position.y - sectionPosition.y}px)`,
        willChange: "transform",
        pointerEvents: "none"
      }}
    >
      <svg
        width={pieceSize}
        height={pieceSize}
        viewBox={`${piece.x * CELL_SIZE - TAB_PADDING} ${
          piece.y * CELL_SIZE - TAB_PADDING
        } ${CELL_SIZE + 2 * TAB_PADDING} ${CELL_SIZE + 2 * TAB_PADDING}`}
        style={{ pointerEvents: "none" }}
      >
        <defs>
          <filter id={`shadow-back-${piece.id}`} x="-20%" y="-20%" width="140%" height="140%">
            <feDropShadow 
              dx="0"
              dy="2"
              stdDeviation="2"
              floodOpacity="0.2"
            />
          </filter>
        </defs>
        <path
          d={piece.svgPath}
          fill="#ffffff"
          stroke="#e5e5e5"
          strokeWidth="0.5"
          filter={`url(#shadow-back-${piece.id})`}
        />
      </svg>
    </div>
  );
}

interface GlowEffectProps {
  x: number;
  y: number;
  intensity: number;  // 0 to 1
}

const GlowEffect = React.memo(({ x, y, intensity }: GlowEffectProps) => {
  const opacity = Math.min(0.25, intensity * 0.4);
  const blur = Math.min(30, intensity * 60);
  const size = 70;
  
  return (
    <div
      className="absolute rounded-full pointer-events-none"
      style={{
        left: x - size / 2,
        top: y - size / 2,
        width: size,
        height: size,
        backgroundColor: `rgba(255, 215, 0, ${opacity})`,
        boxShadow: `0 0 ${blur}px ${blur}px rgba(255, 215, 0, ${opacity})`,
        transition: 'all 0.1s ease-out',
      }}
    />
  );
});

export default function PuzzleBoard({
  pieces,
  width,
  height,
  image,
  onComplete,
  isFlipped = false
}: PuzzleBoardProps) {
  const maxXIndex = Math.max(...pieces.map((p) => p.x)) + 1;
  const maxYIndex = Math.max(...pieces.map((p) => p.y)) + 1;

  const finalPuzzleShapeMaxWidth = width * 0.70;
  const finalPuzzleShapeMaxHeight = height * 0.85;
  
  // Calculate dimensions maintaining aspect ratio
  const puzzleAspectRatio = maxXIndex / maxYIndex;
  const containerAspectRatio = finalPuzzleShapeMaxWidth / finalPuzzleShapeMaxHeight;
  
  let actualPuzzleWidth, actualPuzzleHeight;
  if (containerAspectRatio > puzzleAspectRatio) {
    // Height is constraining factor
    actualPuzzleHeight = finalPuzzleShapeMaxHeight;
    actualPuzzleWidth = finalPuzzleShapeMaxHeight * puzzleAspectRatio;
  } else {
    // Width is constraining factor
    actualPuzzleWidth = finalPuzzleShapeMaxWidth;
    actualPuzzleHeight = finalPuzzleShapeMaxWidth / puzzleAspectRatio;
  }

  const basePieceSize = Math.min(finalPuzzleShapeMaxWidth / maxXIndex, finalPuzzleShapeMaxHeight / maxYIndex);
  const pieceSize = basePieceSize * 1.3;

  const padding = 50;

  // Initialize piece positions using custom hook for center starting position
  const [piecePositions, setPiecePositions] = useState(
    useInitialPiecePositions(pieces, width, height, maxXIndex, maxYIndex, padding)
  );

  // Add state to track animation
  const [hasAnimated, setHasAnimated] = useState(false);

  const [lastMergeTimestamp, setLastMergeTimestamp] = useState<number | undefined>();

  // Add state for cached merge targets
  const [currentMergeTargets, setCurrentMergeTargets] = useState<MergeOpportunity[]>([]);

  // Add state for active glows
  const [activeGlows, setActiveGlows] = useState<Array<{x: number, y: number, intensity: number}>>([]);

  // Add effect to trigger the animation
  useEffect(() => {
    if (!hasAnimated) {
      const finalPositions = calculateFinalPositions(pieces, width, height, maxXIndex, maxYIndex, padding);
      
      // Calculate random delays for each piece
      const delays: Record<string, number> = {};
      pieces.forEach((piece) => {
        delays[piece.id] = Math.random() * 600; // Random delay between 50ms and 450ms
      });

      // Animate each piece with its own delay
      pieces.forEach((piece) => {
        setTimeout(() => {
          setPiecePositions(prev => ({
            ...prev,
            [piece.id]: finalPositions[piece.id]
          }));
        }, delays[piece.id]);
      });

      setHasAnimated(true);
    }
  }, [hasAnimated, pieces, width, height, maxXIndex, maxYIndex, padding]);

  const { sections, setSections } = useSectionManagement(pieces, onComplete);

  // Handle dragging a section
  const handleSectionMove = useCallback((sectionId: string, deltaX: number, deltaY: number) => {
    const sectionPieces = getSectionPieces(sections, sectionId);
    
    // Calculate new positions
    const newPositions = { ...piecePositions };
    sectionPieces.forEach(pieceId => {
      newPositions[pieceId] = {
        x: piecePositions[pieceId].x + deltaX,
        y: piecePositions[pieceId].y + deltaY
      };
    });

    // Update positions
    setPiecePositions(newPositions);

    // Calculate glows for nearby targets
    const GLOW_THRESHOLD = 50; // Start glowing within 70px
    const newGlows = currentMergeTargets
      .map(({ piece, targetPosition, direction }) => {
        const currentPos = newPositions[piece.id];
        const currentPosOfImpact = getOriginToMidpoint(direction, currentPos, pieceSize);
        const dx = currentPosOfImpact.x - targetPosition.x;
        const dy = currentPosOfImpact.y - targetPosition.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        
        if (distance > GLOW_THRESHOLD) return null;
        
        const intensity = 1 - (distance / GLOW_THRESHOLD);
        const renderedPadding = (TAB_PADDING / widthOfViewBox) * pieceSize;
        const xOffset = direction === "right" ? -renderedPadding/2 : (direction === "left" ? renderedPadding/2 : 0);
        const yOffset = direction === "bottom" ? -renderedPadding/2 : (direction === "top" ? renderedPadding/2 : 0);
        return {
          x: targetPosition.x + xOffset,
          y: targetPosition.y + yOffset,
          intensity
        };
      })
      .filter((glow): glow is {x: number, y: number, intensity: number} => glow !== null);

    setActiveGlows(newGlows);

    // Calculate and log current distances to targets
    currentMergeTargets.forEach(({ piece, neighborPiece, direction, targetPosition }) => {
      const currentPos = newPositions[piece.id];
      const dx = currentPos.x - targetPosition.x;
      const dy = currentPos.y - targetPosition.y;
      const currentDistance = Math.sqrt(dx * dx + dy * dy);

      console.log(
        `Current distance from piece ${piece.id} to ${neighborPiece.id} ` +
          `(${direction}): ${currentDistance.toFixed(2)}px`
      );
    });
  }, [sections, piecePositions, currentMergeTargets]);

  const handleSectionDrop = useCallback((droppedSectionId: string, newPosition: PiecePosition) => {
    setActiveGlows([]);
    const sectionPieces = getSectionPieces(sections, droppedSectionId);
    
    const sectionPositions = sectionPieces.map(id => piecePositions[id]);
    const minX = Math.min(...sectionPositions.map(p => p.x));
    const minY = Math.min(...sectionPositions.map(p => p.y));
    
    const deltaX = newPosition.x - minX;
    const deltaY = newPosition.y - minY;

    const movedPositions = { ...piecePositions };
    sectionPieces.forEach(pieceId => {
      movedPositions[pieceId] = {
        x: piecePositions[pieceId].x + deltaX,
        y: piecePositions[pieceId].y + deltaY
      };
    });

    const SNAP_THRESHOLD = 30;
    const { mergeOpportunities } = findMergeOpportunities(
      pieces,
      sectionPieces,
      piecePositions,
      pieceSize,
      SNAP_THRESHOLD,
      sections
    );

    if (mergeOpportunities.length > 0) {
      const currentSection = findSection(sections, droppedSectionId);
      if (!currentSection) return;

      const { currentSections, currentPositions } = handleSectionMerging(
        sectionPieces,
        mergeOpportunities,
        currentSection,
        sections,
        movedPositions,
        pieceSize
      );

      setSections(currentSections);
      setPiecePositions(currentPositions);
      setLastMergeTimestamp(Date.now());
      setCurrentMergeTargets([]); // Clear targets after merge
    } else {
      const repelledPositions = repelOverlappingSections(
        sectionPieces,
        sections,
        movedPositions,
        pieceSize
      );
      setPiecePositions(repelledPositions);
      setCurrentMergeTargets([]); // Clear targets after drop
    }
  }, [pieces, piecePositions, pieceSize, sections]);

  // Memoize sections to render calculation
  const sectionsToRender = useMemo(() => pieces.reduce<{ pieces: PuzzlePieceType[], sectionId: string }[]>((acc, piece) => {
    const section = findSection(sections, piece.id);
    if (section) {
      const existingSection = acc.find(s => s.sectionId === Array.from(section)[0]);
      if (existingSection) {
        existingSection.pieces.push(piece);
      } else {
        acc.push({
          sectionId: Array.from(section)[0],
          pieces: [piece]
        });
      }
    } else {
      acc.push({
        sectionId: piece.id,
        pieces: [piece]
      });
    }
    return acc;
  }, []), [pieces, sections]);

  const renderPuzzleBack = useCallback(() => (
    <div className="absolute inset-0">
      {sectionsToRender.map(({ sectionId, pieces: sectionPieces }) => {
        const positions = sectionPieces.map(piece => piecePositions[piece.id]);
        const sectionPosition = {
          x: Math.min(...positions.map(p => p.x)),
          y: Math.min(...positions.map(p => p.y)),
        };

        return (
          <div 
            key={sectionId} 
            className="absolute" 
            style={{
              transform: `translate(${sectionPosition.x}px, ${sectionPosition.y}px)`,
              willChange: "transform",
              pointerEvents: "none"
            }}
          >
            {sectionPieces.map(piece => (
              <PuzzlePieceBack
                key={piece.id}
                piece={piece}
                pieceSize={pieceSize}
                position={piecePositions[piece.id]}
                sectionPosition={sectionPosition}
              />
            ))}
          </div>
        );
      })}
    </div>
  ), [sectionsToRender, piecePositions, pieceSize]);

  // Handle section pickup
  const handleSectionPickup = useCallback((sectionId: string) => {
    const sectionPieces = getSectionPieces(sections, sectionId);
    const { allDistances } = findMergeOpportunities(
      pieces,
      sectionPieces,
      piecePositions,
      pieceSize,
      Infinity,
      sections
    );

    // Cache the merge targets
    setCurrentMergeTargets(allDistances);

    // Log initial distances
    allDistances.forEach(({ piece, neighborPiece, direction, distance, targetPosition }) => {
      console.log(
        `Merge opportunity from piece ${piece.id} to ${neighborPiece.id} ` +
        `(${direction}): ${distance.toFixed(2)}px\n` +
        `Target position: (${targetPosition.x.toFixed(2)}, ${targetPosition.y.toFixed(2)})`
      );
    });
  }, [pieces, sections, piecePositions, pieceSize]);

  return (
    <div
      className="relative bg-amber-900/20 backdrop-blur-md rounded-3xl"
      style={{ width, height }}
    >
      {/* Render glows */}
      {activeGlows.map((glow, i) => (
        <GlowEffect key={i} {...glow} />
      ))}

      <div
        className={`absolute inset-0 transition-transform duration-1000 transform-style-preserve-3d ${
          isFlipped ? "rotate-y-180" : ""
        }`}
      >
        <div className="absolute inset-0 backface-hidden">
          {sectionsToRender.map(({ sectionId, pieces: sectionPieces }) => {
            const positions = sectionPieces.map(
              (piece) => piecePositions[piece.id]
            );
            const sectionPosition = {
              x: Math.min(...positions.map((p) => p.x)),
              y: Math.min(...positions.map((p) => p.y)),
            };

            return (
              <PuzzleSection
                key={sectionId}
                sectionId={sectionId}
                pieces={sectionPieces}
                piecePositions={piecePositions}
                sectionPosition={sectionPosition}
                pieceSize={pieceSize}
                boardWidth={width}
                boardHeight={height}
                maxX={maxXIndex}
                maxY={maxYIndex}
                image={image}
                onMove={handleSectionMove}
                onDrop={handleSectionDrop}
                lastMergeTimestamp={lastMergeTimestamp}
                onPickup={handleSectionPickup}
              />
            );
          })}
        </div>
        <div className="absolute inset-0 backface-hidden rotate-y-180 rounded-3xl">
          {renderPuzzleBack()}
          {sectionsToRender.length > 0 && (
            <div
              className="absolute flex items-center justify-center"
              style={{
                left: `${sectionsToRender[0].pieces
                  .map((piece) => piecePositions[piece.id].x)
                  .reduce((a, b) => Math.min(a, b))}px`,
                top: `${sectionsToRender[0].pieces
                  .map((piece) => piecePositions[piece.id].y)
                  .reduce((a, b) => Math.min(a, b))}px`,
                width: `${actualPuzzleWidth}px`,
                height: `${actualPuzzleHeight}px`,
              }}
            >
              <div className="handwritten-alt text-gray-800 text-3xl font-bold text-center transform -rotate-6">
                Dear gfil,
                <br />
                Merry Kellymas
                <br />
                ❤️ Love, bf &nbsp;
                <br />
                <br />
                {/* <a href="/mailbox/2024" className="text-red-600 text-2xl">
                  Love letter this way →
                </a> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
