import { PuzzlePreset } from '../types';
import { PRESET_CONFIGS } from '../config';
import { encodePuzzle } from '../components/PuzzleExport';

const STORAGE_KEY = 'current-puzzle';
const STARTED_KEY = 'puzzle-started';

export async function loadPuzzleData(preset: PuzzlePreset): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const puzzleData = encodePuzzle(
        {
          ...PRESET_CONFIGS.Classic,
          piecesAcross: preset.piecesAcross,
          piecesDown: preset.piecesDown,
          dimensions: {
            width: preset.piecesAcross,
            height: preset.piecesDown,
          },
        },
        img
      );
      resolve(JSON.stringify(puzzleData));
    };
    img.onerror = reject;
    img.src = preset.imageUrl;
  });
}

export function savePuzzleData(puzzleData: string): void {
  localStorage.setItem(STORAGE_KEY, puzzleData);
}

export function clearPuzzleData(): void {
  localStorage.removeItem(STORAGE_KEY);
  localStorage.removeItem(STARTED_KEY);
}

export function getPuzzleData(): string | null {
  return localStorage.getItem(STORAGE_KEY);
}

export function markPuzzleStarted(): void {
  localStorage.setItem(STARTED_KEY, 'true');
}

export function hasPuzzleStarted(): boolean {
  return localStorage.getItem(STARTED_KEY) === 'true';
} 