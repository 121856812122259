import React from 'react';
import { motion } from 'framer-motion';

interface BoxWithMotionProps {
  index: number;
  isLoaded: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export default function BoxWithMotion({
  index,
  isLoaded,
  onClick,
  children
}: BoxWithMotionProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{
        opacity: isLoaded ? 1 : 0,
        y: isLoaded ? 0 : 20,
      }}
      transition={{ delay: index * 0.1 }}
      onClick={onClick}
      className="group relative cursor-pointer"
      style={{
        perspective: "2000px",
        transformStyle: "preserve-3d",
      }}
    >
      <div
        className="relative pt-4 pl-4 transform-gpu transition-all duration-500 group-hover:-translate-y-2"
        style={{
          transformStyle: "preserve-3d",
          transformOrigin: "center center",
          transform: `rotateY(${0}deg)`,
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform =
            "translateY(-0.5rem) rotateY(20deg) scale(1.1)";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = "translateY(0) rotateY(0deg) scale(1)";
        }}
      >
        {children}
      </div>
    </motion.div>
  );
} 