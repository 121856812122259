import React from 'react';

interface CompletionOverlayProps {
  isFlipped: boolean;
  onFlip: () => void;
}

export default function CompletionOverlay({ isFlipped, onFlip }: CompletionOverlayProps) {
  return (
    <>
      <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <h2 className="text-6xl font-bold mb-8 text-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.8)]">
          ❤️🥰🥰🥰🥰❤️
        </h2>
      </div>
      <div className="absolute bottom-[50px] left-1/2 transform -translate-x-1/2 text-center">
        <button
          onClick={onFlip}
          className="bg-black/60 hover:bg-black/30 backdrop-blur-md text-white py-3 px-8 rounded-lg text-3xl transition-colors duration-200"
        >
          {isFlipped ? "Flip back" : "Flip over"}
        </button>
      </div>
    </>
  );
} 