import React from 'react';
import { PuzzlePiece } from './PuzzleControls';

interface PuzzlePreviewProps {
    pieces: PuzzlePiece[];
    width: number;
    height: number;
    image?: HTMLImageElement;
    onPieceClick?: (piece: PuzzlePiece) => void;
}

export default function PuzzlePreview({ pieces, width, height, image, onPieceClick }: PuzzlePreviewProps) {
    // Calculate viewBox dimensions based on pieces
    const maxX = Math.max(...pieces.map(p => p.x)) + 1;
    const maxY = Math.max(...pieces.map(p => p.y)) + 1;
    const viewBoxWidth = maxX * 100;
    const viewBoxHeight = maxY * 100;

    // Calculate image pattern scaling
    const patternWidth = viewBoxWidth;
    const patternHeight = viewBoxHeight;
    const imageScale = Math.max(
        patternWidth / (image?.width || 1),
        patternHeight / (image?.height || 1)
    );
    const scaledWidth = (image?.width || 0) * imageScale;
    const scaledHeight = (image?.height || 0) * imageScale;
    const offsetX = (patternWidth - scaledWidth) / 2;
    const offsetY = (patternHeight - scaledHeight) / 2;

    return (
        <svg 
            width={width} 
            height={height} 
            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
            style={{ backgroundColor: '#f0f0f0' }}
        >
            {image && (
                <defs>
                    <pattern
                        id="puzzle-image"
                        patternUnits="userSpaceOnUse"
                        width={viewBoxWidth}
                        height={viewBoxHeight}
                    >
                        <image
                            href={image.src}
                            x={offsetX}
                            y={offsetY}
                            width={scaledWidth}
                            height={scaledHeight}
                            preserveAspectRatio="xMidYMid slice"
                        />
                    </pattern>
                </defs>
            )}
            
            {pieces.map((piece) => {
                const isEven = (piece.x + piece.y) % 2 === 0;
                return (
                    <path
                        key={piece.id}
                        d={piece.svgPath}
                        fill={image ? `url(#puzzle-image)` : isEven ? '#e5e7eb' : '#d1d5db'}
                        stroke="#000"
                        strokeWidth="0.5"
                        onClick={() => onPieceClick?.(piece)}
                        style={{ cursor: onPieceClick ? 'pointer' : 'default' }}
                    />
                );
            })}
        </svg>
    );
}
