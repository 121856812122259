import React from 'react';
import { PuzzlePreset } from '../puzzle-presets';
import FrontOfBox from './FrontOfBox';
import BoxWithMotion from './BoxWithMotion';

interface PuzzleBoxProps {
  preset: PuzzlePreset;
  index: number;
  isLoaded: boolean;
  onImageLoad: () => void;
  onClick: () => void;
  withMotion?: boolean;
}

function BoxSide() {
  return (
    <div
      className="absolute left-1 top-5 bottom-0 w-4 bg-red-900 origin-right rounded-l-sm overflow-hidden transition-all duration-500 group-hover:w-8 group-hover:-left-3 side-left"
      style={{
        background: "linear-gradient(to right, rgb(153,27,27), rgb(127,29,29))",
        transformStyle: "preserve-3d",
      }}
    >
      <div
        className="absolute top-1/2 left-1/2 -translate-y-1/2 chunky text-2xl group-hover:text-4xl whitespace-nowrap text-red-100/80 group-hover:tracking-[0.1em] tracking-[0.2em] transition-all duration-500"
        style={{
          transform: "translate(-50%, -50%) rotateZ(-90deg) rotateY(-45deg) scaleX(0.7)",
          transformOrigin: "center center",
        }}
      >
        B&nbsp;E&nbsp;Z&nbsp;Z&nbsp;E&nbsp;L&nbsp;&nbsp;&nbsp;&nbsp;P&nbsp;U&nbsp;Z&nbsp;Z&nbsp;L&nbsp;E&nbsp;S
      </div>
    </div>
  );
}

function BoxTop() {
  return (
    <div
      className="absolute top-1 left-5 right-0 h-4 bg-red-950 origin-bottom rounded-t-sm transition-all duration-500 group-hover:h-5 group-hover:top-0 side-top"
      style={{
        background: "linear-gradient(to bottom, rgb(153,27,27), rgb(101,23,23))",
        transformStyle: "preserve-3d",
      }}
    />
  );
}

function BoxShadow() {
  return (
    <div
      className="absolute -bottom-2 left-1/2 w-[90%] h-4 bg-black/20 blur-md -translate-x-1/2 rounded-full transition-all duration-500"
      style={{
        transform: "translateX(-50%) rotateX(70deg)",
        opacity: 1,
      }}
    />
  );
}

function BoxContents({
  preset,
  index,
  onImageLoad,
}: Pick<PuzzleBoxProps, 'preset' | 'index' | 'onImageLoad'>) {
  return (
    <>
      <BoxShadow />
      <FrontOfBox
        preset={preset}
        index={index}
        onImageLoad={onImageLoad}
        className="group-hover:shadow-xl"
      />
      <BoxSide />
      <BoxTop />
    </>
  );
}

export default function PuzzleBox({
  preset,
  index,
  isLoaded,
  onImageLoad,
  onClick,
  withMotion = true,
}: PuzzleBoxProps) {
  if (!withMotion) {
    return (
      <div className="pt-4 pl-4 relative cursor-pointer">
        <BoxContents preset={preset} index={index} onImageLoad={onImageLoad} />
      </div>
    );
  }

  return (
    <BoxWithMotion index={index} isLoaded={isLoaded} onClick={onClick}>
      <BoxContents preset={preset} index={index} onImageLoad={onImageLoad} />
    </BoxWithMotion>
  );
} 