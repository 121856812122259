import React, { useEffect, useRef } from 'react';

const AUDIO_EVENTS = {
    TOGGLE_PLAY: 'AUDIO_TOGGLE_PLAY',
    NEXT_SONG: 'AUDIO_NEXT_SONG'
};

export const audioControls = {
    togglePlayPause: () => {
        console.log('togglePlayPause');
        window.dispatchEvent(new CustomEvent(AUDIO_EVENTS.TOGGLE_PLAY));
    },
    nextSong: () => {
        window.dispatchEvent(new CustomEvent(AUDIO_EVENTS.NEXT_SONG));
    }
};

interface AudioPlayerProps {
    src: string;
    readyToPlay: boolean;
    onFinished?: () => void;
    onPlayStateChange?: (isPlaying: boolean) => void;
}

const AudioPlayer = ({ src, readyToPlay, onFinished, onPlayStateChange }: AudioPlayerProps) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const hasAutoPlayedRef = useRef(false);
    const wasPlayingRef = useRef(false);

    useEffect(() => {
        const audio = audioRef.current;
        if (!audio) return;

        const handlePlay = () => {
            console.log('Audio play event triggered');
            wasPlayingRef.current = true;
            onPlayStateChange?.(true);
        };
        const handlePause = () => {
            console.log('Audio pause event triggered');
            wasPlayingRef.current = false;
            onPlayStateChange?.(false);
        };
        const handleEnded = () => {
            console.log('Audio ended event triggered');
            wasPlayingRef.current = false;
            onPlayStateChange?.(false);
            if (onFinished) {
                onFinished();
            }
        };
        const handleError = (e: any) => console.error('Audio error event triggered', e);

        const handleTogglePlay = () => {
            if (audio.paused) {
                audio.play();
            } else {
                audio.pause();
            }
        };

        const handleSpaceBar = (event: KeyboardEvent) => {
            if (event.code === 'Space') {
                event.preventDefault();
                handleTogglePlay();
            }
        };

        // Add event listeners for audio events
        audio.addEventListener('play', handlePlay);
        audio.addEventListener('pause', handlePause);
        audio.addEventListener('ended', handleEnded);
        audio.addEventListener('error', handleError);

        // Add global event listeners
        window.addEventListener(AUDIO_EVENTS.TOGGLE_PLAY, handleTogglePlay);
        window.addEventListener('keydown', handleSpaceBar);

        // Only auto-play on initial load
        if (readyToPlay && !hasAutoPlayedRef.current) {
            hasAutoPlayedRef.current = true;
            audio.play().catch(e => console.error('Failed to play:', e));
        }

        // Cleanup function
        return () => {
            audio.removeEventListener('play', handlePlay);
            audio.removeEventListener('pause', handlePause);
            audio.removeEventListener('ended', handleEnded);
            audio.removeEventListener('error', handleError);
            window.removeEventListener(AUDIO_EVENTS.TOGGLE_PLAY, handleTogglePlay);
            window.removeEventListener('keydown', handleSpaceBar);
        };
    }, [readyToPlay, onFinished, onPlayStateChange]);

    useEffect(() => {
        const audio = audioRef.current;
        if (!audio) return;

        // Update audio source if changed
        audio.src = src;
        
        // Auto-play if it was playing before
        if (wasPlayingRef.current) {
            audio.play().catch(e => console.error('Failed to play:', e));
        }
    }, [src]);

    return (
        <audio ref={audioRef}>
            <source src={src} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    );
};

export default AudioPlayer; 