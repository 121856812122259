import React, { useState, useEffect } from 'react';
import PuzzleControls, { PuzzleParams, PuzzlePiece } from './components/PuzzleControls';
import PuzzlePreview from './components/PuzzlePreview';
import ImageUpload from './components/ImageUpload';
import PieceDetail from './components/PieceDetail';
import PuzzleExport, { encodePuzzle } from './components/PuzzleExport';
import { generatePuzzlePieces } from './utils/puzzleGenerator';
import { PRESET_CONFIGS } from './config';

const STORAGE_KEY = 'current-puzzle';

export default function Cutter() {
    const [params, setParams] = useState<PuzzleParams>(PRESET_CONFIGS.Classic);

    const [pieces, setPieces] = useState<PuzzlePiece[]>([]);
    const [image, setImage] = useState<HTMLImageElement>();
    const [selectedPiece, setSelectedPiece] = useState<PuzzlePiece>();

    useEffect(() => {
        generateCut();
    }, [params]);

    const generateCut = () => {
        const newPieces = generatePuzzlePieces(params);
        setPieces(newPieces);
    };

    const handleParamsChange = (newParams: PuzzleParams) => {
        setParams(newParams);
    };

    const handleImageLoad = (img: HTMLImageElement) => {
        setImage(img);
        // Update dimensions based on image aspect ratio
        const aspectRatio = img.width / img.height;
        if (aspectRatio > 1) {
            setParams(prev => ({
                ...prev,
                dimensions: { width: Math.round(aspectRatio * 2), height: 2 }
            }));
        } else {
            setParams(prev => ({
                ...prev,
                dimensions: { width: 2, height: Math.round(2 / aspectRatio) }
            }));
        }
    };

    const handleSaveToLocalStorage = () => {
        if (!image) {
            alert('Please upload an image first');
            return;
        }

        const puzzleData = encodePuzzle(params, image);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(puzzleData));
        alert('Puzzle saved! You can now play it in the Bezzel page.');
    };

    // Calculate preview container size
    const maxPreviewWidth = 600;
    const maxPreviewHeight = 600;
    const aspectRatio = params.piecesAcross / params.piecesDown;
    
    let previewWidth, previewHeight;
    if (aspectRatio > 1) {
        previewWidth = maxPreviewWidth;
        previewHeight = maxPreviewWidth / aspectRatio;
    } else {
        previewHeight = maxPreviewHeight;
        previewWidth = maxPreviewHeight * aspectRatio;
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Puzzle Cutter</h1>
            
            <div className="flex gap-8">
                <div className="w-64 space-y-4">
                    <ImageUpload onImageLoad={handleImageLoad} />
                    <PuzzleControls 
                        params={params}
                        onParamsChange={handleParamsChange}
                        onGenerate={generateCut}
                    />
                </div>

                <div className="flex-1">
                    <h2 className="text-lg font-semibold mb-2">Preview</h2>
                    <div 
                        className="border bg-white"
                        style={{ 
                            width: `${previewWidth}px`,
                            height: `${previewHeight}px`,
                            maxWidth: '100%',
                            maxHeight: '100%'
                        }}
                    >
                        <PuzzlePreview 
                            pieces={pieces}
                            width={previewWidth}
                            height={previewHeight}
                            image={image}
                            onPieceClick={setSelectedPiece}
                        />
                    </div>
                </div>

                <div className="w-80">
                    <div className="mb-4 space-y-2">
                        <PuzzleExport params={params} image={image} />
                        <button
                            onClick={handleSaveToLocalStorage}
                            className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            disabled={!image}
                        >
                            Save as Current Puzzle
                        </button>
                    </div>
                    <h2 className="text-lg font-semibold mb-2">Piece Detail</h2>
                    <PieceDetail 
                        piece={selectedPiece}
                        pieces={pieces}
                        image={image}
                    />
                </div>
            </div>
        </div>
    );
}