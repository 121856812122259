import React from 'react';
import { PuzzleParams } from './PuzzleControls';

interface PuzzleData {
    params: PuzzleParams;
    imageData: string;
    version: string;
}

interface PuzzleExportProps {
    params: PuzzleParams;
    image?: HTMLImageElement;
}

function compressImage(image: HTMLImageElement, maxDimension: number = 1200): string {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;

    // Calculate new dimensions while maintaining aspect ratio
    let width = image.width;
    let height = image.height;
    if (width > height) {
        if (width > maxDimension) {
            height = Math.round(height * maxDimension / width);
            width = maxDimension;
        }
    } else {
        if (height > maxDimension) {
            width = Math.round(width * maxDimension / height);
            height = maxDimension;
        }
    }

    // Set canvas size and draw image
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(image, 0, 0, width, height);

    // Convert to compressed JPEG
    return canvas.toDataURL('image/jpeg', 0.7);
}

export function encodePuzzle(params: PuzzleParams, image: HTMLImageElement): PuzzleData {
    const compressedImageData = compressImage(image);
    return {
        params,
        imageData: compressedImageData,
        version: '1.0'
    };
}

export function decodePuzzle(data: PuzzleData): Promise<{ params: PuzzleParams; image: HTMLImageElement }> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const params = {
                ...data.params,
                seed: data.params.seed || "default seed"
            };
            resolve({
                params,
                image: img
            });
        };
        img.onerror = () => {
            reject(new Error('Failed to load puzzle image'));
        };
        img.src = data.imageData;
    });
}

export default function PuzzleExport({ params, image }: PuzzleExportProps) {
    const handleExport = () => {
        if (!image) {
            alert('Please upload an image first');
            return;
        }

        const puzzleData = encodePuzzle(params, image);
        const blob = new Blob([JSON.stringify(puzzleData)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `puzzle-${params.seed || 'random'}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <button
            onClick={handleExport}
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            disabled={!image}
        >
            Export Puzzle
        </button>
    );
}
