export type Letter = {
  body: string[];
  date: Date;
  dateFormatted: string;
  fromEmail: string;
  subject: string;
  link?: {
    text: string;
    href: string;
  };
};

export const LETTER_2023: Letter = {
  body: [
    `dear gf,`,
    ``,
    `it's about time this boy wrote you a letter -- and what a special day to recieve one 🥰`,
    ``,
    `
the happiest of kellymas tidings full of joy and goodness and all things christmasy wintery wonderful.
truly the year of belly, from last kellymas to this one how you've filled my life with vitality exuberance comfort and stability (hard 
to find in twenty twenty three). i could not be more grateful to have you in my life and how our relationship continues to blossom every 
new month.
`,
    ``,
    `
in your heart there truly is a christmas tree farm and it brings me so much love to see your eyes light up your involuntary squeak of 
joy your bouncing up onto your tiptoes when december wraps you up in her arms and brings you the kellymas you fucking deserve. all of 
your trees (20$ from walmart, lego, tiny, postcard with a musical moose, on a website [oops], giant glowing in the middle of manhattan) 
all of the christmas tree lane, all of the cozy in a cabin just the two of us (can we just snuggle?), all of the kellymas musical spirit 
echoing through the world - i love how december tramsforms you and that you transform it right back
`,
    ``,
    `
i love the way we fit into each others' lives. our communication shared brain cell intuition toward one another, our openness and lack 
of judgement the sacredness of our shared space, the constancy and honesty we bring to belly - its truly not something i imagined on my 
2023 bucket list or any bucket list for that matter. i only want belly to continue as strong as we've ever been.
`,
    ``,
    `
you bring such fun adventure cozy homeliness manic intellectual competitive energy. youre both incredibly tidy and dont read 400 texts. 
you're a proudly raging consumerist ✌️. you love food as much as i do. you have such a strong sense of morality. you're smart funny 
beatiful ["obvi"]. words dance through your mind in a way i can never experience. i love it all. you're so incredibly fucking special 
and i fucking love how happy we make each other.
`,
    ``,
    `
love, bf 💗🦆
`,
    ``,
    `
and now enjoy the official KELLYMAS JUKEBOX (tm)`,
  ],
  date: new Date(),
  dateFormatted: "hi",
  fromEmail: "ben",
  subject: "to kelly",
  link: {
    text: "🎶 tunes this way (sound on) 🎶",
    href: "/playlist",
  },
};

export const LETTER_2024: Letter = {
  body: [
    `
        dear gf,
`,
    ``,
    `
        flashes of fire and rich warm waves — surrounding envelop soothe chattering bones
    `,
    ``,
    `
        safety. caress, eye to eye. staring into depths and seeing them back. our held space — where the world disappears. living in the belliverse.
    `,
    ``,
    `
        you hold fire and water in equal measure. the wind at your back and the world yours to shape. i saw your passion for dignity and righteousness in way i didn’t understand when we did ballot measures. 
    `,
    ``,
    `
        to say you have layers misses your essence. they are not layers, they’re interwoven. they’re porous liquids at viscous interplay. they are not hidden under rocks but subtle at the shallow pools. 
    `,
    ``,
    `
        i’ve loved the way we live our lives together. how we can share in our loves. how our cycles on mania have a shared rhythm — backgammon tap tap puzzles tap tap pantoll tap tap — the way belly makes the mundane sparkle.
    `,
    ``,
    `
        the light in your soul, that radiates in every day — fire burning hearth candle — and how grateful i am that you share that light with me. 
    `,
    ``,
    `
        oceans roil. fold. rise. recoil.
        `,
    ` 
        ever replenished.
        `,
    `
        riveting.
    `,
    ``,
    `
        the deeper i see, the more i love you.
        `,
    `
        fire growing, nurtured tended uncontrolled.
    `,
    ``,
    `
        here’s to another belly recharge ever so soon. an energy all our own. forehead to forehead. or leaning. and lamp hats in paris, trains into the wild unknown. because it’s not so unknown together. 
    `,
    ``,
    `
happy birthday.
`,
    `
yours, all yours,
        `, `❤️ bf`
  ],
  date: new Date(),
  dateFormatted: "hi",
  fromEmail: "ben",
  subject: "to kelly",
};
