import { TAB_PADDING, widthOfViewBox } from "../components/PuzzlePiece";
import { PuzzlePiece } from "../components/PuzzleControls";

export interface PiecePosition {
  x: number;
  y: number;
}

export interface PieceDistance {
  neighborId: string;
  direction: "left" | "right" | "top" | "bottom";
  distance: number;
  targetPosition: PiecePosition;
}

// Calculate the correct snapped position for a piece relative to its neighbor
export function calculateSnappedPosition(
  direction: "left" | "right" | "top" | "bottom",
  neighborPos: PiecePosition,
  pieceSize: number
): PiecePosition {
  const renderedPadding = (TAB_PADDING / widthOfViewBox) * pieceSize;

  switch (direction) {
    case "left":
      return {
        x: neighborPos.x + pieceSize - 2 * renderedPadding,
        y: neighborPos.y,
      };
    case "right":
      return {
        x: neighborPos.x - pieceSize + 2 * renderedPadding,
        y: neighborPos.y,
      };
    case "top":
      return {
        x: neighborPos.x,
        y: neighborPos.y + pieceSize - 2 * renderedPadding,
      };
    case "bottom":
      return {
        x: neighborPos.x,
        y: neighborPos.y - pieceSize + 2 * renderedPadding,
      };
  }
}

interface EdgeInfo {
  targetPosition: PiecePosition;
  distance: number;
}

export type Direction = "top" | "right" | "bottom" | "left";
export function getOriginToMidpoint(
  direction: Direction,
  piecePosition: PiecePosition,
  pieceSize: number
): PiecePosition {
  const renderedPadding = (TAB_PADDING / widthOfViewBox) * pieceSize;

  switch (direction) {
    case "left":
      return {
        x: piecePosition.x + renderedPadding,
        y: piecePosition.y + pieceSize / 2,
      };
    case "right":
      return {
        x: piecePosition.x + pieceSize - renderedPadding,
        y: piecePosition.y + pieceSize / 2,
      };
    case "top":
      return {
        x: piecePosition.x + pieceSize / 2,
        y: piecePosition.y + renderedPadding,
      };
    case "bottom":
      return {
        x: piecePosition.x + pieceSize / 2,
        y: piecePosition.y + pieceSize - renderedPadding,
      };
  }
}

export function getEdgeInfo(
  currentPos: PiecePosition,
  neighborPos: PiecePosition,
  direction: Direction,
  pieceSize: number
): EdgeInfo {
  const alignment =
    direction === "top" || direction === "bottom"
      ? Math.abs(currentPos.x - neighborPos.x)
      : Math.abs(currentPos.y - neighborPos.y);

  switch (direction) {
    case "top": {
      let currentY = getOriginToMidpoint("top", currentPos, pieceSize).y;
      const targetPosition = getOriginToMidpoint(
        "bottom",
        neighborPos,
        pieceSize
      );

      return {
        targetPosition: targetPosition,
        distance: Math.sqrt(
          Math.pow(currentY - targetPosition.y, 2) + Math.pow(alignment, 2)
        ),
      };
    }
    case "right": {
      let currentX = getOriginToMidpoint("right", currentPos, pieceSize).x;
      const targetPosition = getOriginToMidpoint(
        "left",
        neighborPos,
        pieceSize
      );


      return {
        targetPosition,
        distance: Math.sqrt(
          Math.pow(currentX - targetPosition.x, 2) + Math.pow(alignment, 2)
        ),
      };
    }
    case "bottom": {
      let currentY = getOriginToMidpoint("bottom", currentPos, pieceSize).y;
      const targetPosition = getOriginToMidpoint(
        "top",
        neighborPos,
        pieceSize
      );

      return {
        targetPosition,
        distance: Math.sqrt(
          Math.pow(currentY - targetPosition.y, 2) + Math.pow(alignment, 2)
        ),
      };
    }
    case "left": {
      let currentX = getOriginToMidpoint("left", currentPos, pieceSize).x;
      const targetPosition = getOriginToMidpoint(
        "right",
        neighborPos,
        pieceSize
      );
      return {
        targetPosition,
        distance: Math.sqrt(
          Math.pow(currentX - targetPosition.x, 2) + Math.pow(alignment, 2)
        ),
      };
    }
  }
}

export function getNeighborsFast(
  piece: PuzzlePiece,
  piecesInGrid: PuzzlePiece[][]
): { neighbor: PuzzlePiece; direction: Direction }[] {
  const neighbors: { neighbor: PuzzlePiece; direction: Direction }[] = [];
  const { x, y } = piece;

  // Check top neighbor if not at top edge
  if (y > 0 && piecesInGrid[y - 1]?.[x]) {
    neighbors.push({
      neighbor: piecesInGrid[y - 1][x],
      direction: "top",
    });
  }

  // Check right neighbor if not at right edge
  if (x < piecesInGrid[y].length - 1 && piecesInGrid[y]?.[x + 1]) {
    neighbors.push({
      neighbor: piecesInGrid[y][x + 1],
      direction: "right",
    });
  }

  // Check bottom neighbor if not at bottom edge
  if (y < piecesInGrid.length - 1 && piecesInGrid[y + 1]?.[x]) {
    neighbors.push({
      neighbor: piecesInGrid[y + 1][x],
      direction: "bottom",
    });
  }

  // Check left neighbor if not at left edge
  if (x > 0 && piecesInGrid[y]?.[x - 1]) {
    neighbors.push({
      neighbor: piecesInGrid[y][x - 1],
      direction: "left",
    });
  }

  return neighbors;
}

export function getNeighborsSlow(
  piece: PuzzlePiece,
  allPieces: PuzzlePiece[]
): { neighbor: PuzzlePiece; direction: Direction }[] {
  return allPieces
    .filter((p) => {
      // Same row, adjacent column
      const isHorizontalNeighbor =
        p.y === piece.y && Math.abs(p.x - piece.x) === 1;
      // Same column, adjacent row
      const isVerticalNeighbor =
        p.x === piece.x && Math.abs(p.y - piece.y) === 1;
      return isHorizontalNeighbor || isVerticalNeighbor;
    })
    .map((neighbor) => ({
      neighbor,
      direction: getDirection(piece, neighbor),
    }));
}

function getDirection(piece: PuzzlePiece, neighbor: PuzzlePiece): Direction {
  if (neighbor.y < piece.y) return "top";
  if (neighbor.x > piece.x) return "right";
  if (neighbor.y > piece.y) return "bottom";
  return "left";
}
