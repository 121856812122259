import React from 'react';
import { PuzzlePiece } from './PuzzleControls';

interface PieceDetailProps {
    piece?: PuzzlePiece;
    pieces: PuzzlePiece[];
    image?: HTMLImageElement;
}

export default function PieceDetail({ piece, pieces, image }: PieceDetailProps) {
    if (!piece) {
        return (
            <div className="bg-white rounded-lg p-6 w-full h-full flex items-center justify-center text-gray-500">
                Click a piece to see details
            </div>
        );
    }

    // Calculate viewBox to include the piece and its neighbors
    const viewBoxX = piece.x * 100 - 50;  // Include half a piece width on each side
    const viewBoxY = piece.y * 100 - 50;
    const viewBoxWidth = 200;  // Show 2 pieces width
    const viewBoxHeight = 200; // Show 2 pieces height

    // Calculate full puzzle dimensions for image scaling
    const puzzleWidth = Math.max(...pieces.map(p => p.x)) + 1;
    const puzzleHeight = Math.max(...pieces.map(p => p.y)) + 1;
    const fullWidth = puzzleWidth * 100;
    const fullHeight = puzzleHeight * 100;

    // Calculate image pattern scaling
    const imageScale = Math.max(
        fullWidth / (image?.width || 1),
        fullHeight / (image?.height || 1)
    );
    const scaledWidth = (image?.width || 0) * imageScale;
    const scaledHeight = (image?.height || 0) * imageScale;
    const offsetX = (fullWidth - scaledWidth) / 2;
    const offsetY = (fullHeight - scaledHeight) / 2;

    return (
        <div className="bg-white rounded-lg p-6 w-full">
            <h2 className="text-xl font-bold mb-4">Piece {piece.id}</h2>
            
            <div className="aspect-square w-full bg-gray-100 rounded-lg overflow-hidden">
                <svg 
                    viewBox={`${viewBoxX} ${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`}
                    className="w-full h-full"
                >
                    {image && (
                        <defs>
                            <pattern
                                id="detail-image"
                                patternUnits="userSpaceOnUse"
                                width={fullWidth}
                                height={fullHeight}
                            >
                                <image
                                    href={image.src}
                                    x={offsetX}
                                    y={offsetY}
                                    width={scaledWidth}
                                    height={scaledHeight}
                                    preserveAspectRatio="xMidYMid slice"
                                />
                            </pattern>
                        </defs>
                    )}
                    {pieces.map(p => (
                        <path
                            key={p.id}
                            d={p.svgPath}
                            fill={image ? `url(#detail-image)` : p.id === piece.id ? '#fff' : '#f3f4f6'}
                            stroke="#000"
                            strokeWidth="0.5"
                            opacity={p.id === piece.id ? 1 : 0.3}
                        />
                    ))}
                </svg>
            </div>

            <div className="mt-4 grid grid-cols-2 gap-4 text-sm">
                <div>
                    <div className="font-semibold">Position</div>
                    <div>X: {piece.x}, Y: {piece.y}</div>
                </div>
                <div>
                    <div className="font-semibold">Edges</div>
                    <div>
                        Top: {piece.edges.top > 0 ? 'Tab' : piece.edges.top < 0 ? 'Indent' : 'Flat'}<br />
                        Right: {piece.edges.right > 0 ? 'Tab' : piece.edges.right < 0 ? 'Indent' : 'Flat'}<br />
                        Bottom: {piece.edges.bottom > 0 ? 'Tab' : piece.edges.bottom < 0 ? 'Indent' : 'Flat'}<br />
                        Left: {piece.edges.left > 0 ? 'Tab' : piece.edges.left < 0 ? 'Indent' : 'Flat'}
                    </div>
                </div>
            </div>
        </div>
    );
}
