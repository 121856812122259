import React, { memo } from 'react';
import { PuzzlePiece as PuzzlePieceType } from './PuzzleControls';

interface PuzzlePieceProps {
    piece: PuzzlePieceType;
    image?: HTMLImageElement;
    width: number;
    height: number;
    x: number;
    y: number;
    maxX: number;
    maxY: number;
}

export const CELL_SIZE = 100;
export const TAB_PADDING = 30;
export const widthOfViewBox = CELL_SIZE + 2 * TAB_PADDING;
export const heightOfViewBox = CELL_SIZE + 2 * TAB_PADDING;

function PuzzlePiece({ piece, image, width, height, x, y, maxX, maxY }: PuzzlePieceProps) {
    // Calculate image scaling to match preview
    const viewBoxWidth = maxX * CELL_SIZE;
    const viewBoxHeight = maxY * CELL_SIZE;
    const imageScale = image ? Math.max(
        viewBoxWidth / image.width,
        viewBoxHeight / image.height
    ) : 1;
    const scaledWidth = image ? image.width * imageScale : 0;
    const scaledHeight = image ? image.height * imageScale : 0;
    const offsetX = (viewBoxWidth - scaledWidth) / 2;
    const offsetY = (viewBoxHeight - scaledHeight) / 2;

    return (
      <div
        className="absolute"
        style={{
          width,
          height,
          transform: `translate(${x}px, ${y}px)`,
          willChange: "transform",
          pointerEvents: "none"
        }}
      >
        <svg
          width={width}
          height={height}
          viewBox={`${piece.x * CELL_SIZE - TAB_PADDING} ${
            piece.y * CELL_SIZE - TAB_PADDING
          } ${CELL_SIZE + 2 * TAB_PADDING} ${CELL_SIZE + 2 * TAB_PADDING}`}
          style={{ pointerEvents: "none" }}
        >
          <defs>
            <filter id={`shadow-${piece.id}`} x="-20%" y="-20%" width="140%" height="140%">
              <feDropShadow 
                dx="0"
                dy="2"
                stdDeviation="3"
                floodOpacity="0.5"
              />
            </filter>
            {image && (
              <pattern
                id={`piece-image-${piece.id}`}
                patternUnits="userSpaceOnUse"
                width={viewBoxWidth}
                height={viewBoxHeight}
              >
                <image
                  href={image.src}
                  x={offsetX}
                  y={offsetY}
                  width={scaledWidth}
                  height={scaledHeight}
                  preserveAspectRatio="xMidYMid slice"
                />
              </pattern>
            )}
          </defs>
          <path
            d={piece.svgPath}
            fill={image ? `url(#piece-image-${piece.id})` : "#fff"}
            stroke="#000"
            strokeWidth="0.5"
            filter={`url(#shadow-${piece.id})`}
          />
        </svg>
      </div>
    );
}

export default memo(PuzzlePiece); 